import React, { Fragment, useEffect, useState } from 'react';
import './globalFooter.less';
import { useGlobalFooterQuery } from 'src/graphql';
import Newsletter from './footer-component/Newsletter';
import FooterMenu from './footer-component/FooterMenuComponent';
import FooterCopyRight from './footer-component/FooterCopyRight';
import { SvgIcon } from '../ui/SvgIcon';

interface FooterComponents {
    newsletter: any | null;
    footerMenu: any | null;
    address: any | null;
    secondaryMenu: any[];
    copyRight: any | null;
    socialMedia: any | null;
}

const GlobalFooter = () => {
    const { data, loading } = useGlobalFooterQuery();
    const [footerComponents, setFooterComponents] = useState<FooterComponents>({
        newsletter: null,
        footerMenu: null,
        address: null,
        secondaryMenu: [],
        copyRight: null,
        socialMedia: null,
    });

    useEffect(() => {
        if (data) {
            const globalFooter = data.globalFooter?.data?.attributes?.Footer || [];
            const extractedComponents = extractFooterComponents(globalFooter);
            setFooterComponents(extractedComponents);
        }
    }, [data]);

    const extractFooterComponents = (globalFooter: any[]) => {
        const newsletter = globalFooter.find((item: any) => item.__typename === "ComponentUiNewsletter");
        const footerMenu = globalFooter.find((item: any) => item.__typename === "ComponentUiFooterMenu");
        const address = globalFooter.find((item: any) => item.__typename === "ComponentUiXpAddress");
        const secondaryMenu = globalFooter.filter((item: any) => item.__typename === "ComponentUiFooterSecondaryMenu");
        const copyRight = globalFooter.find((item: any) => item.__typename === "ComponentUiFooterCopyRight");
        const socialMedia = globalFooter.find((item: any) => item.__typename === "ComponentUiXpSocialMedia");
        return { newsletter, footerMenu, address, secondaryMenu, copyRight, socialMedia };
    };
    
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//code.tidio.co/hv4bfkdjlphobv3mu79gwwiewdvv5ljo.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <Fragment>

        { !loading && <footer className={'footerWrapper bckLightBlackWhite'}>
           {/* <SvgIcon type={"BlackClip"} className={"footerBackgroundImg"} />  */}
            <div className={""} id={"newsletter"} >
                {/* Newsletter */}
                {footerComponents.newsletter && (
                    <Newsletter
                        title={footerComponents.newsletter.title}
                        isActive={footerComponents.newsletter.isActive}
                        description={footerComponents.newsletter.description}
                        SubscribeForm={footerComponents.newsletter.SubscribeForm}
                    />
                )}

                {/* Footer Menu */}
                {footerComponents.footerMenu && (
                    <FooterMenu
                        footermenuIsActive={footerComponents.footerMenu.isActive}
                        footerMenu={footerComponents.footerMenu.footer_menus.data}
                        address={footerComponents.address}
                        secondaryMenu={footerComponents?.secondaryMenu}
                        secondaryMenuIsActive={footerComponents?.secondaryMenu?.some(menu => menu.isActive)}
                        socialMedia={footerComponents.socialMedia?.footerSocialIcon}
                        socialMediaTitle={footerComponents.socialMedia?.title}
                        socialMediaIsActive={footerComponents.socialMedia?.isActive}
                    />
                )}

                {/* Footer Copy Right */}
                {footerComponents.copyRight && (
                    <FooterCopyRight
                        copyRightTitle={footerComponents.copyRight.title}
                        copyRightIsActive={footerComponents.copyRight.isActive}
                    />
                )}
            </div>
        </footer>}
        </Fragment>
    );
};

export default GlobalFooter;
