import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "BlogPostArticleContentDynamicZone": [
      "ComponentUiBackgroundHighlightBlock",
      "ComponentUiCenterTextBlock",
      "ComponentUiCtaBlock",
      "ComponentUiHighlightText",
      "ComponentUiImageBlock",
      "ComponentUiQuoteBlock",
      "ComponentUiStatsBox",
      "ComponentUiTextBlock",
      "Error"
    ],
    "ChallengeArticleContentDynamicZone": [
      "ComponentUiBackgroundHighlightBlock",
      "ComponentUiCenterTextBlock",
      "ComponentUiCtaBlock",
      "ComponentUiHighlightText",
      "ComponentUiImageBlock",
      "ComponentUiQuoteBlock",
      "ComponentUiStatsBox",
      "ComponentUiTextBlock",
      "Error"
    ],
    "ChannelPartnerChannelPartnerSectionsDynamicZone": [
      "ComponentSharedChannelPartnersSection",
      "ComponentSharedCpCardSection",
      "ComponentSharedCpFaq",
      "ComponentSharedSeo",
      "ComponentUiD365CardSection",
      "ComponentUiD365ServiceSection",
      "ComponentUiHero",
      "ComponentUiListLinks",
      "ComponentUiMethodologySection",
      "ComponentUiOpenContactForm",
      "ComponentUiSubscribeSection",
      "Error"
    ],
    "D365ImplementationServiceD365SectionsDynamicZone": [
      "ComponentSharedFormSection",
      "ComponentSharedSeo",
      "ComponentUiD365CardSection",
      "ComponentUiD365ServiceSection",
      "ComponentUiHero",
      "ComponentUiHeroBannerD365",
      "ComponentUiMethodologySection",
      "ComponentUiSubscribeSection",
      "Error"
    ],
    "EventCardEventDetailedSectionDynamicZone": [
      "ComponentSharedAgendaSection",
      "ComponentSharedEventHostSection",
      "ComponentSharedExpectFromWebinarSection",
      "ComponentSharedFaqSection",
      "ComponentSharedIndustryBenefit",
      "ComponentSharedJoinWebinar",
      "ComponentSharedOverviewSection",
      "ComponentSharedWebinarFeatureSection",
      "ComponentSharedWebinarIsForSection",
      "Error"
    ],
    "GenericMorph": [
      "AdBoxBanner",
      "BlogAuthor",
      "BlogPost",
      "BlogTag",
      "Brochure",
      "Career",
      "CareerType",
      "Category",
      "Challenge",
      "ChannelPartner",
      "ComponentPageAbout",
      "ComponentPageContactUs",
      "ComponentPageCookie",
      "ComponentPageCulture",
      "ComponentPageEula",
      "ComponentPageEventDetailedPageSection",
      "ComponentPageEvents",
      "ComponentPageFaq",
      "ComponentPageHome",
      "ComponentPageIndustry",
      "ComponentPageIndustryBenefit",
      "ComponentPageNews",
      "ComponentPagePage",
      "ComponentPagePrivacyPolicy",
      "ComponentPageProducts",
      "ComponentPageScheduleMeeitng",
      "ComponentPageScheduleMeeting",
      "ComponentPageServicePackagesOffers",
      "ComponentPageServices",
      "ComponentPageSitemap",
      "ComponentPageWhyXcelPros",
      "ComponentPageWriteForUs",
      "ComponentSharedAgendaList",
      "ComponentSharedAgendaSection",
      "ComponentSharedButton",
      "ComponentSharedChannelPartnersSection",
      "ComponentSharedCpCardSection",
      "ComponentSharedCpFaq",
      "ComponentSharedEventCard",
      "ComponentSharedEventDetailedSection",
      "ComponentSharedEventDetails",
      "ComponentSharedEventFeatureCard",
      "ComponentSharedEventFeatureList",
      "ComponentSharedEventHostCard",
      "ComponentSharedEventHostSection",
      "ComponentSharedEventsCard",
      "ComponentSharedExpectFromWebinarSection",
      "ComponentSharedFaqSection",
      "ComponentSharedFormInput",
      "ComponentSharedFormSection",
      "ComponentSharedIndustryBenefit",
      "ComponentSharedJoinWebinar",
      "ComponentSharedListName",
      "ComponentSharedOverviewSection",
      "ComponentSharedSearchBar",
      "ComponentSharedSeo",
      "ComponentSharedTitle",
      "ComponentSharedWebinarFeatureSection",
      "ComponentSharedWebinarIsForSection",
      "ComponentUiAgendaSection",
      "ComponentUiAgreement",
      "ComponentUiAgreementItem",
      "ComponentUiAgreementSubitem",
      "ComponentUiAlertBanner",
      "ComponentUiAlertMessage",
      "ComponentUiAnchorLink",
      "ComponentUiAnchorList",
      "ComponentUiBackgroundHighlightBlock",
      "ComponentUiCard",
      "ComponentUiCenterTextBlock",
      "ComponentUiChannelPartnersCard",
      "ComponentUiCtaBlock",
      "ComponentUiD365CardList",
      "ComponentUiD365CardSection",
      "ComponentUiD365Cards",
      "ComponentUiD365ServiceCard",
      "ComponentUiD365ServiceSection",
      "ComponentUiDropDownMenuList",
      "ComponentUiEventHostSection",
      "ComponentUiEventSectionCard",
      "ComponentUiFaqList",
      "ComponentUiFaqSection",
      "ComponentUiFooterCopyRight",
      "ComponentUiFooterMenu",
      "ComponentUiFooterMenuList",
      "ComponentUiFooterMenuListItem",
      "ComponentUiFooterSecondaryMenu",
      "ComponentUiHero",
      "ComponentUiHeroBanner",
      "ComponentUiHeroBannerD365",
      "ComponentUiHighlightText",
      "ComponentUiImageBlock",
      "ComponentUiImplementationServiceCard",
      "ComponentUiInnerNav",
      "ComponentUiInput",
      "ComponentUiJoinWebinarBanner",
      "ComponentUiLink",
      "ComponentUiLinksGroup",
      "ComponentUiListLinks",
      "ComponentUiLogo",
      "ComponentUiMainLinks",
      "ComponentUiMainMenu",
      "ComponentUiMenuActionIcon",
      "ComponentUiMenuCta",
      "ComponentUiMenuIcon",
      "ComponentUiMenuType",
      "ComponentUiMethodologySection",
      "ComponentUiMethodologySectionD365",
      "ComponentUiNewsletter",
      "ComponentUiOpenContactForm",
      "ComponentUiPhaseList",
      "ComponentUiPolicySection",
      "ComponentUiProjectPhaseDetail",
      "ComponentUiQuoteBlock",
      "ComponentUiRegisterButton",
      "ComponentUiScrollableContent",
      "ComponentUiSection",
      "ComponentUiSectionCards",
      "ComponentUiSectionChapters",
      "ComponentUiSectionImg",
      "ComponentUiSectionTestimonial",
      "ComponentUiSectionTextList",
      "ComponentUiServiceCard",
      "ComponentUiServiceCardD365",
      "ComponentUiSocialIcons",
      "ComponentUiStatsBox",
      "ComponentUiSubscribeSection",
      "ComponentUiTestimonial",
      "ComponentUiText",
      "ComponentUiTextBlock",
      "ComponentUiTextList",
      "ComponentUiTitlText",
      "ComponentUiTitleWithButton",
      "ComponentUiTopMenu",
      "ComponentUiXpAddress",
      "ComponentUiXpForm",
      "ComponentUiXpSocialMedia",
      "CustomerJourney",
      "D365ImplementationService",
      "DarkHeaderThemePath",
      "DropCard",
      "DropMenuList",
      "Event",
      "EventCard",
      "EventFeature",
      "EventForm",
      "EventHost",
      "EventPage",
      "Faq",
      "Footer",
      "FooterMenu",
      "Form",
      "GlobalFooter",
      "GlobalHeader",
      "Header",
      "IconLink",
      "ImplementationServicesCard",
      "Industry",
      "InnerLinksGroup",
      "InsightMeetingCard",
      "InsightType",
      "LightHeaderThemePage",
      "MethodologyProjectPhase",
      "OurProductsHeaderThemePage",
      "Page",
      "PopUpContactUs",
      "PopUpSubscribe",
      "Product",
      "ReactIconsIconlibrary",
      "Redirecting",
      "Registration",
      "ResourceAccessUser",
      "Service",
      "Slide",
      "SubscribeForm",
      "TrendingCategory",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Website",
      "Whitepaper",
      "WriteForUsResponse"
    ],
    "GlobalFooterFooterDynamicZone": [
      "ComponentUiFooterCopyRight",
      "ComponentUiFooterMenu",
      "ComponentUiFooterSecondaryMenu",
      "ComponentUiNewsletter",
      "ComponentUiXpAddress",
      "ComponentUiXpSocialMedia",
      "Error"
    ],
    "GlobalHeaderNavbarDynamicZone": [
      "ComponentUiAlertBanner",
      "ComponentUiLogo",
      "ComponentUiMainMenu",
      "ComponentUiMenuCta",
      "ComponentUiMenuIcon",
      "ComponentUiTopMenu",
      "Error"
    ],
    "PageContentDynamicZone": [
      "ComponentPageAbout",
      "ComponentPageContactUs",
      "ComponentPageCookie",
      "ComponentPageCulture",
      "ComponentPageEula",
      "ComponentPageEvents",
      "ComponentPageFaq",
      "ComponentPageHome",
      "ComponentPageIndustry",
      "ComponentPageNews",
      "ComponentPagePage",
      "ComponentPagePrivacyPolicy",
      "ComponentPageProducts",
      "ComponentPageScheduleMeeting",
      "ComponentPageServicePackagesOffers",
      "ComponentPageServices",
      "ComponentPageSitemap",
      "ComponentPageWhyXcelPros",
      "ComponentPageWriteForUs",
      "Error"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BlogPostArticleContentDynamicZone": [
      "ComponentUiBackgroundHighlightBlock",
      "ComponentUiCenterTextBlock",
      "ComponentUiCtaBlock",
      "ComponentUiHighlightText",
      "ComponentUiImageBlock",
      "ComponentUiQuoteBlock",
      "ComponentUiStatsBox",
      "ComponentUiTextBlock",
      "Error"
    ],
    "ChallengeArticleContentDynamicZone": [
      "ComponentUiBackgroundHighlightBlock",
      "ComponentUiCenterTextBlock",
      "ComponentUiCtaBlock",
      "ComponentUiHighlightText",
      "ComponentUiImageBlock",
      "ComponentUiQuoteBlock",
      "ComponentUiStatsBox",
      "ComponentUiTextBlock",
      "Error"
    ],
    "ChannelPartnerChannelPartnerSectionsDynamicZone": [
      "ComponentSharedChannelPartnersSection",
      "ComponentSharedCpCardSection",
      "ComponentSharedCpFaq",
      "ComponentSharedSeo",
      "ComponentUiD365CardSection",
      "ComponentUiD365ServiceSection",
      "ComponentUiHero",
      "ComponentUiListLinks",
      "ComponentUiMethodologySection",
      "ComponentUiOpenContactForm",
      "ComponentUiSubscribeSection",
      "Error"
    ],
    "D365ImplementationServiceD365SectionsDynamicZone": [
      "ComponentSharedFormSection",
      "ComponentSharedSeo",
      "ComponentUiD365CardSection",
      "ComponentUiD365ServiceSection",
      "ComponentUiHero",
      "ComponentUiHeroBannerD365",
      "ComponentUiMethodologySection",
      "ComponentUiSubscribeSection",
      "Error"
    ],
    "EventCardEventDetailedSectionDynamicZone": [
      "ComponentSharedAgendaSection",
      "ComponentSharedEventHostSection",
      "ComponentSharedExpectFromWebinarSection",
      "ComponentSharedFaqSection",
      "ComponentSharedIndustryBenefit",
      "ComponentSharedJoinWebinar",
      "ComponentSharedOverviewSection",
      "ComponentSharedWebinarFeatureSection",
      "ComponentSharedWebinarIsForSection",
      "Error"
    ],
    "GenericMorph": [
      "AdBoxBanner",
      "BlogAuthor",
      "BlogPost",
      "BlogTag",
      "Brochure",
      "Career",
      "CareerType",
      "Category",
      "Challenge",
      "ChannelPartner",
      "ComponentPageAbout",
      "ComponentPageContactUs",
      "ComponentPageCookie",
      "ComponentPageCulture",
      "ComponentPageEula",
      "ComponentPageEventDetailedPageSection",
      "ComponentPageEvents",
      "ComponentPageFaq",
      "ComponentPageHome",
      "ComponentPageIndustry",
      "ComponentPageIndustryBenefit",
      "ComponentPageNews",
      "ComponentPagePage",
      "ComponentPagePrivacyPolicy",
      "ComponentPageProducts",
      "ComponentPageScheduleMeeitng",
      "ComponentPageScheduleMeeting",
      "ComponentPageServicePackagesOffers",
      "ComponentPageServices",
      "ComponentPageSitemap",
      "ComponentPageWhyXcelPros",
      "ComponentPageWriteForUs",
      "ComponentSharedAgendaList",
      "ComponentSharedAgendaSection",
      "ComponentSharedButton",
      "ComponentSharedChannelPartnersSection",
      "ComponentSharedCpCardSection",
      "ComponentSharedCpFaq",
      "ComponentSharedEventCard",
      "ComponentSharedEventDetailedSection",
      "ComponentSharedEventDetails",
      "ComponentSharedEventFeatureCard",
      "ComponentSharedEventFeatureList",
      "ComponentSharedEventHostCard",
      "ComponentSharedEventHostSection",
      "ComponentSharedEventsCard",
      "ComponentSharedExpectFromWebinarSection",
      "ComponentSharedFaqSection",
      "ComponentSharedFormInput",
      "ComponentSharedFormSection",
      "ComponentSharedIndustryBenefit",
      "ComponentSharedJoinWebinar",
      "ComponentSharedListName",
      "ComponentSharedOverviewSection",
      "ComponentSharedSearchBar",
      "ComponentSharedSeo",
      "ComponentSharedTitle",
      "ComponentSharedWebinarFeatureSection",
      "ComponentSharedWebinarIsForSection",
      "ComponentUiAgendaSection",
      "ComponentUiAgreement",
      "ComponentUiAgreementItem",
      "ComponentUiAgreementSubitem",
      "ComponentUiAlertBanner",
      "ComponentUiAlertMessage",
      "ComponentUiAnchorLink",
      "ComponentUiAnchorList",
      "ComponentUiBackgroundHighlightBlock",
      "ComponentUiCard",
      "ComponentUiCenterTextBlock",
      "ComponentUiChannelPartnersCard",
      "ComponentUiCtaBlock",
      "ComponentUiD365CardList",
      "ComponentUiD365CardSection",
      "ComponentUiD365Cards",
      "ComponentUiD365ServiceCard",
      "ComponentUiD365ServiceSection",
      "ComponentUiDropDownMenuList",
      "ComponentUiEventHostSection",
      "ComponentUiEventSectionCard",
      "ComponentUiFaqList",
      "ComponentUiFaqSection",
      "ComponentUiFooterCopyRight",
      "ComponentUiFooterMenu",
      "ComponentUiFooterMenuList",
      "ComponentUiFooterMenuListItem",
      "ComponentUiFooterSecondaryMenu",
      "ComponentUiHero",
      "ComponentUiHeroBanner",
      "ComponentUiHeroBannerD365",
      "ComponentUiHighlightText",
      "ComponentUiImageBlock",
      "ComponentUiImplementationServiceCard",
      "ComponentUiInnerNav",
      "ComponentUiInput",
      "ComponentUiJoinWebinarBanner",
      "ComponentUiLink",
      "ComponentUiLinksGroup",
      "ComponentUiListLinks",
      "ComponentUiLogo",
      "ComponentUiMainLinks",
      "ComponentUiMainMenu",
      "ComponentUiMenuActionIcon",
      "ComponentUiMenuCta",
      "ComponentUiMenuIcon",
      "ComponentUiMenuType",
      "ComponentUiMethodologySection",
      "ComponentUiMethodologySectionD365",
      "ComponentUiNewsletter",
      "ComponentUiOpenContactForm",
      "ComponentUiPhaseList",
      "ComponentUiPolicySection",
      "ComponentUiProjectPhaseDetail",
      "ComponentUiQuoteBlock",
      "ComponentUiRegisterButton",
      "ComponentUiScrollableContent",
      "ComponentUiSection",
      "ComponentUiSectionCards",
      "ComponentUiSectionChapters",
      "ComponentUiSectionImg",
      "ComponentUiSectionTestimonial",
      "ComponentUiSectionTextList",
      "ComponentUiServiceCard",
      "ComponentUiServiceCardD365",
      "ComponentUiSocialIcons",
      "ComponentUiStatsBox",
      "ComponentUiSubscribeSection",
      "ComponentUiTestimonial",
      "ComponentUiText",
      "ComponentUiTextBlock",
      "ComponentUiTextList",
      "ComponentUiTitlText",
      "ComponentUiTitleWithButton",
      "ComponentUiTopMenu",
      "ComponentUiXpAddress",
      "ComponentUiXpForm",
      "ComponentUiXpSocialMedia",
      "CustomerJourney",
      "D365ImplementationService",
      "DarkHeaderThemePath",
      "DropCard",
      "DropMenuList",
      "Event",
      "EventCard",
      "EventFeature",
      "EventForm",
      "EventHost",
      "EventPage",
      "Faq",
      "Footer",
      "FooterMenu",
      "Form",
      "GlobalFooter",
      "GlobalHeader",
      "Header",
      "IconLink",
      "ImplementationServicesCard",
      "Industry",
      "InnerLinksGroup",
      "InsightMeetingCard",
      "InsightType",
      "LightHeaderThemePage",
      "MethodologyProjectPhase",
      "OurProductsHeaderThemePage",
      "Page",
      "PopUpContactUs",
      "PopUpSubscribe",
      "Product",
      "ReactIconsIconlibrary",
      "Redirecting",
      "Registration",
      "ResourceAccessUser",
      "Service",
      "Slide",
      "SubscribeForm",
      "TrendingCategory",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Website",
      "Whitepaper",
      "WriteForUsResponse"
    ],
    "GlobalFooterFooterDynamicZone": [
      "ComponentUiFooterCopyRight",
      "ComponentUiFooterMenu",
      "ComponentUiFooterSecondaryMenu",
      "ComponentUiNewsletter",
      "ComponentUiXpAddress",
      "ComponentUiXpSocialMedia",
      "Error"
    ],
    "GlobalHeaderNavbarDynamicZone": [
      "ComponentUiAlertBanner",
      "ComponentUiLogo",
      "ComponentUiMainMenu",
      "ComponentUiMenuCta",
      "ComponentUiMenuIcon",
      "ComponentUiTopMenu",
      "Error"
    ],
    "PageContentDynamicZone": [
      "ComponentPageAbout",
      "ComponentPageContactUs",
      "ComponentPageCookie",
      "ComponentPageCulture",
      "ComponentPageEula",
      "ComponentPageEvents",
      "ComponentPageFaq",
      "ComponentPageHome",
      "ComponentPageIndustry",
      "ComponentPageNews",
      "ComponentPagePage",
      "ComponentPagePrivacyPolicy",
      "ComponentPageProducts",
      "ComponentPageScheduleMeeting",
      "ComponentPageServicePackagesOffers",
      "ComponentPageServices",
      "ComponentPageSitemap",
      "ComponentPageWhyXcelPros",
      "ComponentPageWriteForUs",
      "Error"
    ]
  }
};
      export default result;
    
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const SeoFragmentDoc = gql`
    fragment Seo on ComponentSharedSeo {
  id
  canonicalURL
  keywords
  metaTitle
  metaViewport
  metaDescription
  metaRobots
  structuredData
  metaImage {
    data {
      ...File
    }
  }
}
    `;
export const BlogPostFragmentDoc = gql`
    fragment BlogPost on BlogPost {
  content
  articleContent {
    __typename
    ... on ComponentUiTextBlock {
      text
    }
    ... on ComponentUiImageBlock {
      figureCaption
      figureNumber
      image {
        data {
          ...File
        }
      }
    }
    ... on ComponentUiStatsBox {
      statNumber
      text
      statSource
    }
    ... on ComponentUiHighlightText {
      text
    }
    ... on ComponentUiCtaBlock {
      text
      backgroundColor
      ctaLink {
        title
        url
        icon {
          data {
            ...File
          }
        }
      }
    }
    ... on ComponentUiBackgroundHighlightBlock {
      text
      backgroundColor
    }
    ... on ComponentUiCenterTextBlock {
      text
    }
    ... on ComponentUiQuoteBlock {
      text
      source
    }
  }
  cover {
    data {
      ...File
    }
  }
  title
  slug
  innerNav {
    anchor
    title
  }
  seo {
    ...Seo
  }
  tags {
    data {
      attributes {
        tag
      }
    }
  }
  formCTA
  createdAt
  gated
  file {
    data {
      ...File
    }
  }
  category {
    data {
      attributes {
        category
      }
    }
  }
  PrimaryCategory {
    data {
      attributes {
        category
      }
    }
  }
  secondary_categories {
    data {
      attributes {
        category
      }
    }
  }
  BlogKeywords {
    data {
      attributes {
        tag
      }
    }
  }
  blog_authors {
    data {
      attributes {
        Name
        Profile {
          data {
            attributes {
              name
              alternativeText
              caption
              width
              height
              url
            }
          }
        }
        Designation
        About
        Company
      }
    }
  }
}
    `;
export const BlogPostCardFragmentDoc = gql`
    fragment BlogPostCard on BlogPost {
  title
  gated
  cover {
    data {
      ...File
    }
  }
  slug
  content
  trending_categories {
    data {
      attributes {
        category
      }
    }
  }
  category {
    data {
      attributes {
        category
      }
    }
  }
  PrimaryCategory {
    data {
      attributes {
        category
      }
    }
  }
  secondary_categories {
    data {
      attributes {
        category
      }
    }
  }
  BlogKeywords {
    data {
      attributes {
        tag
      }
    }
  }
  blog_authors {
    data {
      attributes {
        Name
        Profile {
          data {
            attributes {
              name
              alternativeText
              caption
              width
              height
              url
            }
          }
        }
        Designation
        About
        Company
      }
    }
  }
  createdAt
  isPopular
}
    `;
export const BrochureFragmentDoc = gql`
    fragment Brochure on Brochure {
  title
  cover {
    data {
      ...File
    }
  }
  file {
    data {
      attributes {
        url
      }
    }
  }
  category {
    data {
      attributes {
        category
      }
    }
  }
  createdAt
  gated
}
    `;
export const CPfaqsFragmentDoc = gql`
    fragment CPfaqs on ComponentSharedCpFaq {
  faqs {
    data {
      id
      attributes {
        isActive
        Question
        SubText
        Answer
        Link {
          id
          Title
          Redirect
          Target
          CTAImage {
            data {
              id
              attributes {
                name
                caption
                url
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const CtaLinksFragmentFragmentDoc = gql`
    fragment CTALinksFragment on ComponentUiNewsletter {
  CTALinks {
    Title
    Redirect
    Target
    isActive
    CTAImage {
      data {
        attributes {
          url
        }
      }
    }
  }
}
    `;
export const CareerCardFragmentDoc = gql`
    fragment CareerCard on Career {
  title
  slug
  location
  career_type {
    data {
      attributes {
        type
      }
    }
  }
  createdAt
}
    `;
export const ChallengeFragmentDoc = gql`
    fragment Challenge on Challenge {
  content
  articleContent {
    __typename
    ... on ComponentUiTextBlock {
      text
    }
    ... on ComponentUiImageBlock {
      figureCaption
      figureNumber
      image {
        data {
          ...File
        }
      }
    }
    ... on ComponentUiStatsBox {
      statNumber
      text
      statSource
    }
    ... on ComponentUiHighlightText {
      text
    }
    ... on ComponentUiCtaBlock {
      text
      backgroundColor
      ctaLink {
        title
        url
        icon {
          data {
            ...File
          }
        }
      }
    }
    ... on ComponentUiBackgroundHighlightBlock {
      text
      backgroundColor
    }
    ... on ComponentUiCenterTextBlock {
      text
    }
    ... on ComponentUiQuoteBlock {
      text
      source
    }
  }
  cover {
    data {
      ...File
    }
  }
  file {
    data {
      ...File
    }
  }
  title
  slug
  innerNav {
    anchor
    title
  }
  seo {
    ...Seo
  }
  formCTA
  category {
    data {
      attributes {
        category
      }
    }
  }
  createdAt
  gated
  blog_authors {
    data {
      attributes {
        Name
        Profile {
          data {
            attributes {
              name
              alternativeText
              caption
              width
              height
              url
            }
          }
        }
        Designation
        About
        Company
      }
    }
  }
}
    `;
export const ChallengeCardFragmentDoc = gql`
    fragment ChallengeCard on Challenge {
  title
  cover {
    data {
      ...File
    }
  }
  file {
    data {
      ...File
    }
  }
  category {
    data {
      attributes {
        category
      }
    }
  }
  slug
  createdAt
}
    `;
export const CustomerJourneyFragmentDoc = gql`
    fragment CustomerJourney on CustomerJourney {
  content
  cover {
    data {
      ...File
    }
  }
  title
  slug
  innerNav {
    anchor
    title
  }
  seo {
    ...Seo
  }
  formCTA
  createdAt
  category {
    data {
      attributes {
        category
      }
    }
  }
  file {
    data {
      attributes {
        url
      }
    }
  }
  gated
}
    `;
export const CustomerJourneyCardFragmentDoc = gql`
    fragment CustomerJourneyCard on CustomerJourney {
  title
  cover {
    data {
      ...File
    }
  }
  slug
  file {
    data {
      ...File
    }
  }
  category {
    data {
      attributes {
        category
      }
    }
  }
  createdAt
}
    `;
export const FooterMenuFragmentFragmentDoc = gql`
    fragment FooterMenuFragment on ComponentUiFooterMenu {
  footer_menus {
    data {
      id
      attributes {
        title
        redirect
        target
        isActive
        icon {
          data {
            attributes {
              url
            }
          }
        }
        footerMenuListItem {
          id
          title
          redirect
          badgeTitle
          badgeActive
          target
          isActive
          icon {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const FooterSecondaryMenuFragmentFragmentDoc = gql`
    fragment FooterSecondaryMenuFragment on ComponentUiFooterSecondaryMenu {
  title
  redirect
  target
  isActive
  icon {
    data {
      attributes {
        url
      }
    }
  }
  secondaryLink {
    Title
    Redirect
    isActive
    Target
    Icon {
      data {
        attributes {
          url
        }
      }
    }
  }
}
    `;
export const FooterSocialMediaFragmentFragmentDoc = gql`
    fragment FooterSocialMediaFragment on ComponentUiXpSocialMedia {
  footerSocialIcon {
    title
    redirect
    target
    isActive
    icon {
      data {
        attributes {
          url
        }
      }
    }
  }
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  title
  url
  isMeeting
  sectionId
  icon {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  file {
    data {
      attributes {
        url
      }
    }
  }
}
    `;
export const TextFragmentDoc = gql`
    fragment Text on ComponentUiText {
  text
}
    `;
export const HeroFragmentDoc = gql`
    fragment Hero on ComponentUiHero {
  sup
  title
  list
  img {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  backgroundImage {
    data {
      attributes {
        url
      }
    }
  }
  underlineLinks {
    ...Link
  }
  btnLinks {
    ...Link
  }
  bckColor
  gradientBackground
  isSupBottom
  searchPlaceholder
  bannerClass
  searchOptions {
    ...Text
  }
  textBtnContactUs
}
    `;
export const ListLinksFragmentDoc = gql`
    fragment ListLinks on ComponentUiListLinks {
  title
  sectionId
  links {
    ...Link
  }
  icon {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  links {
    ...Link
  }
  backgroundColor
  backgroundColorArticle
  url
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  title
  sectionId
  subTitle
  sup
  text
  list
  backgroundColor
  gradientBackground
  bannerClass
  backgroundColorCards
  img {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  backgroundImage {
    data {
      attributes {
        url
      }
    }
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  title
  sectionId
  text
  sup
  url
  icon {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  list
  isOpen
  isMessageBox
  file {
    data {
      attributes {
        url
      }
    }
  }
}
    `;
export const NewsFragmentDoc = gql`
    fragment News on ComponentPageNews {
  pathname
  heroSection {
    ...Section
  }
  heroSectionBtnLinks {
    ...Link
  }
  heroSectionsSearchPlaceholder
  carouselCardLinkSection {
    ...Section
  }
  carouselCardLinkCards {
    ...Card
  }
  cardsFullWidthSection {
    ...Section
  }
  cardsFullWidthCards {
    ...Card
  }
  cardsFullWidthLinks {
    ...Link
  }
  seo {
    ...Seo
  }
}
    `;
export const InputFragmentDoc = gql`
    fragment Input on ComponentUiInput {
  placeholder
  name
  label
  type
  isFullWidth
}
    `;
export const HomeFragmentDoc = gql`
    fragment Home on ComponentPageHome {
  pathname
  heroSection {
    ...Section
  }
  heroSectionsLinks {
    ...Link
  }
  linksSection {
    ...Section
  }
  links {
    ...Link
  }
  cardsWidth30Section {
    ...Section
  }
  cardsWidth30Cards {
    ...Card
  }
  cardsFullWidthSection {
    ...Section
  }
  cardsFullWidthCards {
    ...Card
  }
  cardsFullWidthLinks {
    ...Link
  }
  cardsOpeningSection {
    ...Section
  }
  cardsOpeningFirstSection {
    ...Section
  }
  cardsOpeningFirstCards {
    ...Card
  }
  cardsOpeningSecondSection {
    ...Section
  }
  cardsOpeningSecondCards {
    ...Card
  }
  cardsOpeningThirdSection {
    ...Section
  }
  cardsOpeningThirdCards {
    ...Card
  }
  cardsOpeningIconSection {
    ...Section
  }
  cardsOpeningIconCards {
    ...Card
  }
  linksHalfWidthSection {
    ...Section
  }
  linksHalfWidthLinks {
    ...Link
  }
  formChallengeSection {
    ...Section
  }
  formChallengeInputs {
    ...Input
  }
  sectionInputBtnSection {
    ...Section
  }
  sectionInputBtnInputs {
    ...Input
  }
  listOpeningSection {
    ...Section
  }
  listOpeningCards {
    ...Card
  }
  trendingInsight
  trendingInsightBckColor
  seo {
    ...Seo
  }
}
    `;
export const CultureFragmentDoc = gql`
    fragment Culture on ComponentPageCulture {
  pathname
  heroSection {
    ...Section
  }
  heroSectionBtnLinks {
    ...Link
  }
  titleTextSection {
    ...Section
  }
  textFullWidthSection {
    ...Section
  }
  textFullWidthCards {
    ...Card
  }
  textWidth30Section {
    ...Section
  }
  textWidth30 {
    ...Text
  }
  trendingInsight
  seo {
    ...Seo
  }
}
    `;
export const PolicySectionFragmentDoc = gql`
    fragment PolicySection on ComponentUiPolicySection {
  title
  text
}
    `;
export const PrivacyPolicyFragmentDoc = gql`
    fragment PrivacyPolicy on ComponentPagePrivacyPolicy {
  pathname
  heroSection {
    ...Section
  }
  heroSectionBtnLinks {
    ...Link
  }
  policySection {
    ...PolicySection
  }
  seo {
    ...Seo
  }
}
    `;
export const ServicePackagesOffersFragmentDoc = gql`
    fragment ServicePackagesOffers on ComponentPageServicePackagesOffers {
  pathname
  heroSection {
    ...Section
  }
  heroSectionBtnLinks {
    ...Link
  }
  heroSectionsSearchPlaceholder
  cardLinkPagination {
    ...Section
  }
  cardLinkChapters {
    ...Text
  }
  cardLinkPaginationCards {
    ...Card
  }
  carouselSection {
    ...Section
  }
  carouselSectionCards {
    ...Card
  }
  seo {
    ...Seo
  }
}
    `;
export const AgreementIntroFragmentDoc = gql`
    fragment AgreementIntro on ComponentUiAgreement {
  textIntro
  list
  textBeforeAgreementContent
}
    `;
export const AgreementSubitemFragmentDoc = gql`
    fragment AgreementSubitem on ComponentUiAgreementSubitem {
  subitemNumber
  subitemText
}
    `;
export const AgreementItemFragmentDoc = gql`
    fragment AgreementItem on ComponentUiAgreementItem {
  itemNumber
  itemTitle
  agreementSubitem {
    ...AgreementSubitem
  }
}
    `;
export const EulaFragmentDoc = gql`
    fragment Eula on ComponentPageEula {
  pathname
  heroSection {
    ...Section
  }
  heroSectionBtnLinks {
    ...Link
  }
  agreementIntro {
    ...AgreementIntro
  }
  agreementItem {
    ...AgreementItem
  }
  seo {
    ...Seo
  }
}
    `;
export const AboutFragmentDoc = gql`
    fragment About on ComponentPageAbout {
  pathname
  heroSection {
    ...Section
  }
  heroSectionBtnLinks {
    ...Link
  }
  cardsImgSection {
    ...Section
  }
  cards {
    ...Card
  }
  textWidth30Section {
    ...Section
  }
  textWidth30 {
    ...Text
  }
  titleTextSection {
    ...Section
  }
  trendingInsight
  trendingInsightBckColor
  seo {
    ...Seo
  }
}
    `;
export const LinksGroupFragmentDoc = gql`
    fragment LinksGroup on ComponentUiLinksGroup {
  groupName
  pathname
  inner_links_groups {
    data {
      attributes {
        innerGroupName
        pathname
        links {
          ...Link
        }
      }
    }
  }
}
    `;
export const SitemapFragmentDoc = gql`
    fragment Sitemap on ComponentPageSitemap {
  pathname
  heroSection {
    ...Section
  }
  heroSectionBtnLinks {
    ...Link
  }
  linksGroup {
    ...LinksGroup
  }
  seo {
    ...Seo
  }
}
    `;
export const CookieFragmentDoc = gql`
    fragment Cookie on ComponentPageCookie {
  pathname
  heroSection {
    ...Section
  }
  heroSectionBtnLinks {
    ...Link
  }
  cookieSection {
    ...Section
  }
  cookieCards {
    ...Card
  }
  cookieLinks {
    ...Link
  }
  seo {
    ...Seo
  }
}
    `;
export const ContactUsFragmentDoc = gql`
    fragment ContactUs on ComponentPageContactUs {
  pathname
  heroSection {
    ...Section
  }
  heroSectionBtnLinks {
    ...Link
  }
  formContactSection {
    ...Section
  }
  formContactInputs {
    ...Input
  }
  seo {
    ...Seo
  }
}
    `;
export const FaqFragmentDoc = gql`
    fragment FAQ on ComponentPageFaq {
  pathname
  heroSection {
    ...Section
  }
  heroSectionBtnLinks {
    ...Link
  }
  heroSectionsSearchPlaceholder
  sectionChapters {
    ...Section
  }
  chapters {
    ...Text
  }
  listOpeningCardsFirst {
    ...Card
  }
  listOpeningCardsSecond {
    ...Card
  }
  listOpeningCardsThird {
    ...Card
  }
  listOpeningCardsFourth {
    ...Card
  }
  listOpeningCardsFifth {
    ...Card
  }
  formContactSection {
    ...Section
  }
  formContactInputs {
    ...Input
  }
  trendingInsight
  trendingInsightBckColor
  seo {
    ...Seo
  }
}
    `;
export const WhyXcelProsFragmentDoc = gql`
    fragment WhyXcelPros on ComponentPageWhyXcelPros {
  pathname
  heroSection {
    ...Section
  }
  heroSectionsLinks {
    ...Link
  }
  cardsNumberSection {
    ...Section
  }
  cardsNumber {
    ...Card
  }
  sectionTitleText {
    ...Section
  }
  sectionTitleTextLink {
    ...Link
  }
  listOpeningSection {
    ...Section
  }
  listOpeningCards {
    ...Card
  }
  cardsWidth30Section {
    ...Section
  }
  cardsWidth30Cards {
    ...Card
  }
  sectionInputBtnSecondSection {
    ...Section
  }
  sectionInputBtnSecondInputs {
    ...Input
  }
  trendingInsight
  trendingInsightBckColor
  seo {
    ...Seo
  }
}
    `;
export const PageShortFragmentDoc = gql`
    fragment PageShort on ComponentPagePage {
  pathname
  heroSection {
    ...Section
  }
  heroSectionBtnLinks {
    ...Link
  }
  sectionTitleText {
    ...Section
  }
  sectionTitleTextLink {
    ...Link
  }
  seo {
    ...Seo
  }
}
    `;
export const TestimonialsFragmentDoc = gql`
    fragment Testimonials on ComponentUiTestimonial {
  text
  rating
  author
}
    `;
export const IndustryFragmentDoc = gql`
    fragment Industry on ComponentPageIndustry {
  pathname
  heroSection {
    ...Section
  }
  heroSectionLinks {
    ...Link
  }
  sectionTitleText {
    ...Section
  }
  cardsOpeningIcon {
    ...Section
  }
  cardsOpeningIconCards {
    ...Card
  }
  img {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  sectionTitleTextFirst {
    ...Section
  }
  sectionTitleTextFirstLink {
    ...Link
  }
  cardsImg {
    ...Section
  }
  cardsImgCards {
    ...Card
  }
  sectionChapters {
    ...Section
  }
  sectionChaptersText {
    ...Text
  }
  cardsChapters {
    ...Section
  }
  cardsChaptersCards {
    ...Card
  }
  sectionFilter {
    ...Section
  }
  sectionFilterCards {
    ...Card
  }
  sectionFilterItemsFirstTitle
  sectionFilterItemsFirstCards {
    ...Card
  }
  sectionFilterItemsFirstLinks {
    ...Link
  }
  sectionFilterItemsSecondTitle
  sectionFilterItemsSecondCards {
    ...Card
  }
  sectionFilterItemsSecondLinks {
    ...Link
  }
  sectionFilterItemsThirdTitle
  sectionFilterItemsThirdCards {
    ...Card
  }
  sectionFilterItemsThirdLinks {
    ...Link
  }
  sectionTestimonial {
    ...Section
  }
  sectionTestimonials {
    ...Testimonials
  }
  sectionTitleTextSecond {
    ...Section
  }
  sectionTitleTextSecondLink {
    ...Link
  }
  listOpeningSection {
    ...Section
  }
  listOpeningCards {
    ...Card
  }
  sectionTitleTextThird {
    ...Section
  }
  sectionTitleTextThirdLink {
    ...Link
  }
  formContactSection {
    ...Section
  }
  formContactInput {
    ...Input
  }
  trendingInsight
  trendingInsightBckColor
  seo {
    ...Seo
  }
}
    `;
export const ProductsFragmentDoc = gql`
    fragment Products on ComponentPageProducts {
  pathname
  heroSection {
    ...Section
  }
  heroSectionLinks {
    ...Link
  }
  listOpeningSection {
    ...Section
  }
  listOpeningCards {
    ...Card
  }
  cardsWidth30 {
    ...Section
  }
  cardsWidth30Cards {
    ...Card
  }
  cardsFullWidth {
    ...Section
  }
  cardsFullWidth30Cards {
    ...Card
  }
  cardsImgCenter {
    ...Section
  }
  cardsImgCenterCards {
    ...Card
  }
  listOpeningSecondSection {
    ...Section
  }
  listOpeningSecondCards {
    ...Card
  }
  formContactSection {
    ...Section
  }
  formContactInputs {
    ...Input
  }
  seo {
    ...Seo
  }
}
    `;
export const ServicesFragmentDoc = gql`
    fragment Services on ComponentPageServices {
  pathname
  heroSection {
    ...Section
  }
  heroSectionLinks {
    ...Link
  }
  heroSectionBtnLinks {
    ...Link
  }
  titleUnderlineSection
  firstUnderlineSection {
    ...Section
  }
  firstUnderlineCards {
    ...Card
  }
  secondUnderlineSection {
    ...Section
  }
  secondUnderlineCards {
    ...Card
  }
  thirdUnderlineSection {
    ...Section
  }
  thirdUnderlineCards {
    ...Card
  }
  fourthUnderlineSection {
    ...Section
  }
  fourthUnderlineCards {
    ...Card
  }
  fifthUnderlineSection {
    ...Section
  }
  fifthUnderlineCards {
    ...Card
  }
  sectionInputBtn {
    ...Section
  }
  sectionInputBtnInputs {
    ...Input
  }
  cardsOpeningIconSection {
    ...Section
  }
  cardsOpeningIconCards {
    ...Card
  }
  cardsOpeningIconLinks {
    ...Link
  }
  sectionTitleText {
    ...Section
  }
  sectionTitleTextLink {
    ...Link
  }
  sectionTestimonial {
    ...Section
  }
  sectionTestimonials {
    ...Testimonials
  }
  formContactSection {
    ...Section
  }
  formContactInputs {
    ...Input
  }
  trendingInsight
  trendingInsightBckColor
  seo {
    ...Seo
  }
}
    `;
export const ScheduleMeetingFragmentDoc = gql`
    fragment ScheduleMeeting on ComponentPageScheduleMeeting {
  pathname
  calendlyURL
  seo {
    ...Seo
  }
}
    `;
export const WriteForUsFragmentDoc = gql`
    fragment WriteForUs on ComponentPageWriteForUs {
  pathname
  heroSection {
    ...Section
  }
  content
  seo {
    ...Seo
  }
}
    `;
export const EventsFragmentDoc = gql`
    fragment Events on ComponentPageEvents {
  pathname
  hero {
    ...Section
  }
  seo {
    ...Seo
  }
  heroSectionLinks {
    ...Link
  }
}
    `;
export const PageFragmentDoc = gql`
    fragment Page on PageEntity {
  attributes {
    content {
      ...News
      ...Home
      ...Culture
      ...PrivacyPolicy
      ...ServicePackagesOffers
      ...Eula
      ...About
      ...Sitemap
      ...Cookie
      ...ContactUs
      ...FAQ
      ...WhyXcelPros
      ...PageShort
      ...Industry
      ...Products
      ...Services
      ...ScheduleMeeting
      ...WriteForUs
      ...Events
    }
  }
}
    `;
export const SectionCardsFragmentDoc = gql`
    fragment SectionCards on ComponentUiSectionCards {
  title
  text
  list
  sectionId
  sup
  cards {
    ...Card
  }
  img {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  links {
    ...Link
  }
  backgroundColorCards
  backgroundColor
}
    `;
export const SectionChaptersFragmentDoc = gql`
    fragment SectionChapters on ComponentUiSectionChapters {
  title
  sectionId
  chapters {
    ...Text
  }
  backgroundColor
  backgroundColorArticle
}
    `;
export const SectionImgFragmentDoc = gql`
    fragment SectionImg on ComponentUiSectionImg {
  title
  sectionId
  subTitle
  sup
  images {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  backgroundColor
}
    `;
export const SectionInputBtnFragmentDoc = gql`
    fragment SectionInputBtn on ComponentUiTitleWithButton {
  title
  text
  list
  sup
  backgroundColor
  img {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  inputs {
    ...Input
  }
  backgroundColorArticle
}
    `;
export const SectionTestimonialFragmentDoc = gql`
    fragment SectionTestimonial on ComponentUiSectionTestimonial {
  title
  sectionId
  testimonials {
    ...Testimonials
  }
  backgroundColor
}
    `;
export const TextListFragmentDoc = gql`
    fragment TextList on ComponentUiTextList {
  text
}
    `;
export const SectionTextListFragmentDoc = gql`
    fragment SectionTextList on ComponentUiSectionTextList {
  title
  sectionId
  text
  textList {
    ...TextList
  }
  backgroundColor
  supTitle
  supTitleBig
  img {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
}
    `;
export const SlideFragmentDoc = gql`
    fragment Slide on Slide {
  content
  cover {
    data {
      ...File
    }
  }
  title
  slug
  innerNav {
    anchor
    title
  }
  seo {
    ...Seo
  }
  tags {
    data {
      attributes {
        tag
      }
    }
  }
  formCTA
  createdAt
  file {
    data {
      attributes {
        url
      }
    }
  }
  category {
    data {
      attributes {
        category
      }
    }
  }
  gated
}
    `;
export const SlideCardFragmentDoc = gql`
    fragment SlideCard on Slide {
  title
  cover {
    data {
      ...File
    }
  }
  file {
    data {
      ...File
    }
  }
  slug
  category {
    data {
      attributes {
        category
      }
    }
  }
  createdAt
}
    `;
export const SubscribeFormFragmentFragmentDoc = gql`
    fragment SubscribeFormFragment on ComponentUiNewsletter {
  SubscribeForm {
    id
    FormLabel
    InputPlaceholder
    errorMessage
    inputType
    isActive
  }
}
    `;
export const TitleTextFragmentDoc = gql`
    fragment TitleText on ComponentUiTitlText {
  title
  text
  sectionId
  link {
    ...Link
  }
  list
  backgroundColor
  img {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
}
    `;
export const WebsiteFragmentDoc = gql`
    fragment Website on Website {
  seo {
    ...Seo
  }
}
    `;
export const WhitepaperFragmentDoc = gql`
    fragment Whitepaper on Whitepaper {
  title
  cover {
    data {
      ...File
    }
  }
  file {
    data {
      attributes {
        url
      }
    }
  }
  category {
    data {
      attributes {
        category
      }
    }
  }
  createdAt
  gated
}
    `;
export const CreateFormDocument = gql`
    mutation createForm($data: FormInput!) {
  createForm(data: $data) {
    data {
      attributes {
        name
        company
        phone
        email
        challenge
        message
        receiveUpdates
      }
    }
  }
}
    `;
export type CreateFormMutationFn = Apollo.MutationFunction<CreateFormMutation, CreateFormMutationVariables>;
export function useCreateFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormMutation, CreateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormMutation, CreateFormMutationVariables>(CreateFormDocument, options);
      }
export type CreateFormMutationHookResult = ReturnType<typeof useCreateFormMutation>;
export type CreateFormMutationResult = Apollo.MutationResult<CreateFormMutation>;
export const CreateResourceAccessUserDocument = gql`
    mutation createResourceAccessUser($data: ResourceAccessUserInput!) {
  createResourceAccessUser(data: $data) {
    data {
      attributes {
        email
      }
    }
  }
}
    `;
export type CreateResourceAccessUserMutationFn = Apollo.MutationFunction<CreateResourceAccessUserMutation, CreateResourceAccessUserMutationVariables>;
export function useCreateResourceAccessUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceAccessUserMutation, CreateResourceAccessUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceAccessUserMutation, CreateResourceAccessUserMutationVariables>(CreateResourceAccessUserDocument, options);
      }
export type CreateResourceAccessUserMutationHookResult = ReturnType<typeof useCreateResourceAccessUserMutation>;
export type CreateResourceAccessUserMutationResult = Apollo.MutationResult<CreateResourceAccessUserMutation>;
export const CreateSubscribeFormDocument = gql`
    mutation createSubscribeForm($data: SubscribeFormInput!) {
  createSubscribeForm(data: $data) {
    data {
      attributes {
        name
        email
        company
        designation
        phone
        receiveUpdates
      }
    }
  }
}
    `;
export type CreateSubscribeFormMutationFn = Apollo.MutationFunction<CreateSubscribeFormMutation, CreateSubscribeFormMutationVariables>;
export function useCreateSubscribeFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscribeFormMutation, CreateSubscribeFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscribeFormMutation, CreateSubscribeFormMutationVariables>(CreateSubscribeFormDocument, options);
      }
export type CreateSubscribeFormMutationHookResult = ReturnType<typeof useCreateSubscribeFormMutation>;
export type CreateSubscribeFormMutationResult = Apollo.MutationResult<CreateSubscribeFormMutation>;
export const CreateWriteForUsResponseDocument = gql`
    mutation createWriteForUsResponse($data: WriteForUsResponseInput!) {
  createWriteForUsResponse(data: $data) {
    data {
      attributes {
        fullName
        email
        jobTitle
        articleSummary
        articleTitle
        areaOfInterest
        availability
      }
    }
  }
}
    `;
export type CreateWriteForUsResponseMutationFn = Apollo.MutationFunction<CreateWriteForUsResponseMutation, CreateWriteForUsResponseMutationVariables>;
export function useCreateWriteForUsResponseMutation(baseOptions?: Apollo.MutationHookOptions<CreateWriteForUsResponseMutation, CreateWriteForUsResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWriteForUsResponseMutation, CreateWriteForUsResponseMutationVariables>(CreateWriteForUsResponseDocument, options);
      }
export type CreateWriteForUsResponseMutationHookResult = ReturnType<typeof useCreateWriteForUsResponseMutation>;
export type CreateWriteForUsResponseMutationResult = Apollo.MutationResult<CreateWriteForUsResponseMutation>;
export const D365ImplementationDocument = gql`
    query D365Implementation {
  d365ImplementationService {
    data {
      id
      attributes {
        D365sections {
          ... on ComponentUiD365ServiceSection {
            id
            SectionName
            isActive
            Title
            Description
            backgroundColor
            subTitle
            sectionCTA {
              id
              Title
              Redirect
              Target
              CTAImage {
                data {
                  id
                  attributes {
                    name
                    url
                  }
                }
              }
              isActive
            }
            ServiceCard {
              id
              isActive
              Title
              service_card_implementation {
                data {
                  id
                  attributes {
                    ServicesName
                    isActive
                    Title
                    Description
                    Media {
                      data {
                        id
                        attributes {
                          name
                          url
                        }
                      }
                    }
                    D365ServiceCard {
                      id
                      CardName
                      isActive
                      Title
                      D365CardList {
                        id
                        isActive
                        Icon {
                          data {
                            id
                            attributes {
                              name
                              url
                            }
                          }
                        }
                        ListName
                        Redirect
                        Description
                      }
                      Icon {
                        data {
                          id
                          attributes {
                            name
                            url
                          }
                        }
                      }
                    }
                    D365CTA {
                      id
                      Title
                      Redirect
                      Target
                      CTAImage {
                        data {
                          id
                          attributes {
                            name
                            url
                          }
                        }
                      }
                      isActive
                    }
                  }
                }
              }
            }
          }
          ... on ComponentUiHeroBannerD365 {
            id
            isActive
            Media {
              data {
                id
                attributes {
                  name
                  url
                }
              }
            }
            Title
            Description
            HeroCTA {
              id
              Title
              Redirect
              Target
              isActive
              CTAImage {
                data {
                  id
                  attributes {
                    name
                    url
                  }
                }
              }
            }
          }
          ... on ComponentUiD365CardSection {
            id
            SectionName
            isActive
            Title
            Description
            subTitle
            sectionCTA {
              id
              Title
              Redirect
              Target
              isActive
              CTAImage {
                data {
                  id
                  attributes {
                    name
                    url
                  }
                }
              }
            }
            D365Cards {
              id
              CardName
              isActive
              Banner {
                data {
                  id
                  attributes {
                    name
                    url
                  }
                }
              }
              Title
              Description
              redirectActive
              RedirectURL
              RedirectTitle
              subTitle
            }
          }
          ... on ComponentUiSubscribeSection {
            id
            SectionName
            isActive
            Title
            subTitle
            Description
            Media {
              data {
                id
                attributes {
                  name
                  url
                }
              }
            }
            CTA {
              id
              Title
              Redirect
              Target
              isActive
              CTAImage {
                data {
                  id
                  attributes {
                    name
                    url
                  }
                }
              }
            }
          }
          ... on ComponentUiMethodologySection {
            id
            isActive
            Title
            Description
            ProjectPhaseTitle
            ProjectDescriptionTitle
            backgroundColor
            methodologyProject {
              data {
                id
                attributes {
                  isActive
                  SectionName
                  PhaseTitle
                  ProjectDetailed {
                    id
                    Title
                    Description
                    isActive
                  }
                }
              }
            }
          }
          ... on ComponentUiHero {
            ...Hero
          }
          ... on ComponentSharedFormSection {
            formContactSection {
              ...Section
            }
            formContactInputs {
              ...Input
            }
          }
          ... on ComponentSharedSeo {
            ...Seo
          }
        }
      }
    }
  }
}
    ${HeroFragmentDoc}
${LinkFragmentDoc}
${TextFragmentDoc}
${SectionFragmentDoc}
${InputFragmentDoc}
${SeoFragmentDoc}
${FileFragmentDoc}`;
export function useD365ImplementationQuery(baseOptions?: Apollo.QueryHookOptions<D365ImplementationQuery, D365ImplementationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<D365ImplementationQuery, D365ImplementationQueryVariables>(D365ImplementationDocument, options);
      }
export function useD365ImplementationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<D365ImplementationQuery, D365ImplementationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<D365ImplementationQuery, D365ImplementationQueryVariables>(D365ImplementationDocument, options);
        }
export type D365ImplementationQueryHookResult = ReturnType<typeof useD365ImplementationQuery>;
export type D365ImplementationLazyQueryHookResult = ReturnType<typeof useD365ImplementationLazyQuery>;
export type D365ImplementationQueryResult = Apollo.QueryResult<D365ImplementationQuery, D365ImplementationQueryVariables>;
export const AdBoxBannerDocument = gql`
    query adBoxBanner {
  adBoxBanners {
    data {
      id
      attributes {
        BannerName
        isActive
        Title
        Description
        BannerCTA {
          Title
          Redirect
          Target
          isActive
          CTAImage {
            data {
              attributes {
                url
              }
            }
          }
        }
        Media {
          data {
            attributes {
              url
            }
          }
        }
        isBackgroundImageActive
        backGroundColorCode
      }
    }
  }
}
    `;
export function useAdBoxBannerQuery(baseOptions?: Apollo.QueryHookOptions<AdBoxBannerQuery, AdBoxBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdBoxBannerQuery, AdBoxBannerQueryVariables>(AdBoxBannerDocument, options);
      }
export function useAdBoxBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdBoxBannerQuery, AdBoxBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdBoxBannerQuery, AdBoxBannerQueryVariables>(AdBoxBannerDocument, options);
        }
export type AdBoxBannerQueryHookResult = ReturnType<typeof useAdBoxBannerQuery>;
export type AdBoxBannerLazyQueryHookResult = ReturnType<typeof useAdBoxBannerLazyQuery>;
export type AdBoxBannerQueryResult = Apollo.QueryResult<AdBoxBannerQuery, AdBoxBannerQueryVariables>;
export const AllBlogPostDocument = gql`
    query allBlogPost {
  blogPosts(sort: "id:desc") {
    data {
      attributes {
        ...BlogPostCard
      }
    }
  }
}
    ${BlogPostCardFragmentDoc}
${FileFragmentDoc}`;
export function useAllBlogPostQuery(baseOptions?: Apollo.QueryHookOptions<AllBlogPostQuery, AllBlogPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBlogPostQuery, AllBlogPostQueryVariables>(AllBlogPostDocument, options);
      }
export function useAllBlogPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBlogPostQuery, AllBlogPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBlogPostQuery, AllBlogPostQueryVariables>(AllBlogPostDocument, options);
        }
export type AllBlogPostQueryHookResult = ReturnType<typeof useAllBlogPostQuery>;
export type AllBlogPostLazyQueryHookResult = ReturnType<typeof useAllBlogPostLazyQuery>;
export type AllBlogPostQueryResult = Apollo.QueryResult<AllBlogPostQuery, AllBlogPostQueryVariables>;
export const AllBrochureDocument = gql`
    query allBrochure {
  brochures(sort: "id:desc") {
    data {
      attributes {
        ...Brochure
      }
    }
  }
}
    ${BrochureFragmentDoc}
${FileFragmentDoc}`;
export function useAllBrochureQuery(baseOptions?: Apollo.QueryHookOptions<AllBrochureQuery, AllBrochureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBrochureQuery, AllBrochureQueryVariables>(AllBrochureDocument, options);
      }
export function useAllBrochureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBrochureQuery, AllBrochureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBrochureQuery, AllBrochureQueryVariables>(AllBrochureDocument, options);
        }
export type AllBrochureQueryHookResult = ReturnType<typeof useAllBrochureQuery>;
export type AllBrochureLazyQueryHookResult = ReturnType<typeof useAllBrochureLazyQuery>;
export type AllBrochureQueryResult = Apollo.QueryResult<AllBrochureQuery, AllBrochureQueryVariables>;
export const AllCareerCardDocument = gql`
    query allCareerCard {
  careers(sort: "id:desc") {
    data {
      attributes {
        ...CareerCard
      }
    }
  }
}
    ${CareerCardFragmentDoc}`;
export function useAllCareerCardQuery(baseOptions?: Apollo.QueryHookOptions<AllCareerCardQuery, AllCareerCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCareerCardQuery, AllCareerCardQueryVariables>(AllCareerCardDocument, options);
      }
export function useAllCareerCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCareerCardQuery, AllCareerCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCareerCardQuery, AllCareerCardQueryVariables>(AllCareerCardDocument, options);
        }
export type AllCareerCardQueryHookResult = ReturnType<typeof useAllCareerCardQuery>;
export type AllCareerCardLazyQueryHookResult = ReturnType<typeof useAllCareerCardLazyQuery>;
export type AllCareerCardQueryResult = Apollo.QueryResult<AllCareerCardQuery, AllCareerCardQueryVariables>;
export const AllCategoryDataDocument = gql`
    query allCategoryData {
  categories {
    data {
      attributes {
        category
        cover {
          data {
            attributes {
              url
            }
          }
        }
        brochure {
          data {
            attributes {
              url
            }
          }
        }
        blogs {
          data {
            attributes {
              ...BlogPostCard
            }
          }
        }
      }
    }
  }
}
    ${BlogPostCardFragmentDoc}
${FileFragmentDoc}`;
export function useAllCategoryDataQuery(baseOptions?: Apollo.QueryHookOptions<AllCategoryDataQuery, AllCategoryDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCategoryDataQuery, AllCategoryDataQueryVariables>(AllCategoryDataDocument, options);
      }
export function useAllCategoryDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCategoryDataQuery, AllCategoryDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCategoryDataQuery, AllCategoryDataQueryVariables>(AllCategoryDataDocument, options);
        }
export type AllCategoryDataQueryHookResult = ReturnType<typeof useAllCategoryDataQuery>;
export type AllCategoryDataLazyQueryHookResult = ReturnType<typeof useAllCategoryDataLazyQuery>;
export type AllCategoryDataQueryResult = Apollo.QueryResult<AllCategoryDataQuery, AllCategoryDataQueryVariables>;
export const AllChallengeDocument = gql`
    query allChallenge {
  challenges(sort: "id:desc") {
    data {
      attributes {
        ...ChallengeCard
      }
    }
  }
}
    ${ChallengeCardFragmentDoc}
${FileFragmentDoc}`;
export function useAllChallengeQuery(baseOptions?: Apollo.QueryHookOptions<AllChallengeQuery, AllChallengeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllChallengeQuery, AllChallengeQueryVariables>(AllChallengeDocument, options);
      }
export function useAllChallengeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllChallengeQuery, AllChallengeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllChallengeQuery, AllChallengeQueryVariables>(AllChallengeDocument, options);
        }
export type AllChallengeQueryHookResult = ReturnType<typeof useAllChallengeQuery>;
export type AllChallengeLazyQueryHookResult = ReturnType<typeof useAllChallengeLazyQuery>;
export type AllChallengeQueryResult = Apollo.QueryResult<AllChallengeQuery, AllChallengeQueryVariables>;
export const AllCustomerJourneyDocument = gql`
    query allCustomerJourney {
  customerJourneys(sort: "id:desc") {
    data {
      attributes {
        ...CustomerJourneyCard
      }
    }
  }
}
    ${CustomerJourneyCardFragmentDoc}
${FileFragmentDoc}`;
export function useAllCustomerJourneyQuery(baseOptions?: Apollo.QueryHookOptions<AllCustomerJourneyQuery, AllCustomerJourneyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCustomerJourneyQuery, AllCustomerJourneyQueryVariables>(AllCustomerJourneyDocument, options);
      }
export function useAllCustomerJourneyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCustomerJourneyQuery, AllCustomerJourneyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCustomerJourneyQuery, AllCustomerJourneyQueryVariables>(AllCustomerJourneyDocument, options);
        }
export type AllCustomerJourneyQueryHookResult = ReturnType<typeof useAllCustomerJourneyQuery>;
export type AllCustomerJourneyLazyQueryHookResult = ReturnType<typeof useAllCustomerJourneyLazyQuery>;
export type AllCustomerJourneyQueryResult = Apollo.QueryResult<AllCustomerJourneyQuery, AllCustomerJourneyQueryVariables>;
export const AllSlideDocument = gql`
    query allSlide {
  slides(sort: "id:desc") {
    data {
      attributes {
        ...SlideCard
      }
    }
  }
}
    ${SlideCardFragmentDoc}
${FileFragmentDoc}`;
export function useAllSlideQuery(baseOptions?: Apollo.QueryHookOptions<AllSlideQuery, AllSlideQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSlideQuery, AllSlideQueryVariables>(AllSlideDocument, options);
      }
export function useAllSlideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSlideQuery, AllSlideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSlideQuery, AllSlideQueryVariables>(AllSlideDocument, options);
        }
export type AllSlideQueryHookResult = ReturnType<typeof useAllSlideQuery>;
export type AllSlideLazyQueryHookResult = ReturnType<typeof useAllSlideLazyQuery>;
export type AllSlideQueryResult = Apollo.QueryResult<AllSlideQuery, AllSlideQueryVariables>;
export const GetSubscribedEmailDocument = gql`
    query getSubscribedEmail {
  subscribeForms {
    data {
      attributes {
        email
      }
    }
  }
}
    `;
export function useGetSubscribedEmailQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscribedEmailQuery, GetSubscribedEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscribedEmailQuery, GetSubscribedEmailQueryVariables>(GetSubscribedEmailDocument, options);
      }
export function useGetSubscribedEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscribedEmailQuery, GetSubscribedEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscribedEmailQuery, GetSubscribedEmailQueryVariables>(GetSubscribedEmailDocument, options);
        }
export type GetSubscribedEmailQueryHookResult = ReturnType<typeof useGetSubscribedEmailQuery>;
export type GetSubscribedEmailLazyQueryHookResult = ReturnType<typeof useGetSubscribedEmailLazyQuery>;
export type GetSubscribedEmailQueryResult = Apollo.QueryResult<GetSubscribedEmailQuery, GetSubscribedEmailQueryVariables>;
export const AllWhitepaperDocument = gql`
    query allWhitepaper {
  whitepapers(sort: "id:desc") {
    data {
      attributes {
        ...Whitepaper
      }
    }
  }
}
    ${WhitepaperFragmentDoc}
${FileFragmentDoc}`;
export function useAllWhitepaperQuery(baseOptions?: Apollo.QueryHookOptions<AllWhitepaperQuery, AllWhitepaperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllWhitepaperQuery, AllWhitepaperQueryVariables>(AllWhitepaperDocument, options);
      }
export function useAllWhitepaperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllWhitepaperQuery, AllWhitepaperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllWhitepaperQuery, AllWhitepaperQueryVariables>(AllWhitepaperDocument, options);
        }
export type AllWhitepaperQueryHookResult = ReturnType<typeof useAllWhitepaperQuery>;
export type AllWhitepaperLazyQueryHookResult = ReturnType<typeof useAllWhitepaperLazyQuery>;
export type AllWhitepaperQueryResult = Apollo.QueryResult<AllWhitepaperQuery, AllWhitepaperQueryVariables>;
export const BlogPostDocument = gql`
    query blogPost($slug: StringFilterInput!) {
  blogPosts(filters: {slug: $slug}) {
    data {
      id
      attributes {
        ...BlogPost
      }
    }
  }
}
    ${BlogPostFragmentDoc}
${FileFragmentDoc}
${SeoFragmentDoc}`;
export function useBlogPostQuery(baseOptions: Apollo.QueryHookOptions<BlogPostQuery, BlogPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogPostQuery, BlogPostQueryVariables>(BlogPostDocument, options);
      }
export function useBlogPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogPostQuery, BlogPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogPostQuery, BlogPostQueryVariables>(BlogPostDocument, options);
        }
export type BlogPostQueryHookResult = ReturnType<typeof useBlogPostQuery>;
export type BlogPostLazyQueryHookResult = ReturnType<typeof useBlogPostLazyQuery>;
export type BlogPostQueryResult = Apollo.QueryResult<BlogPostQuery, BlogPostQueryVariables>;
export const CareerDocument = gql`
    query career($slug: StringFilterInput!) {
  careers(filters: {slug: $slug}) {
    data {
      attributes {
        title
        slug
        img {
          data {
            attributes {
              alternativeText
              url
            }
          }
        }
        supTitle
        experience
        location
        openings
        careerDescriptions {
          ...SectionTextList
        }
        links {
          ...Link
        }
        seo {
          ...Seo
        }
        createdAt
      }
    }
  }
}
    ${SectionTextListFragmentDoc}
${TextListFragmentDoc}
${LinkFragmentDoc}
${SeoFragmentDoc}
${FileFragmentDoc}`;
export function useCareerQuery(baseOptions: Apollo.QueryHookOptions<CareerQuery, CareerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerQuery, CareerQueryVariables>(CareerDocument, options);
      }
export function useCareerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerQuery, CareerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerQuery, CareerQueryVariables>(CareerDocument, options);
        }
export type CareerQueryHookResult = ReturnType<typeof useCareerQuery>;
export type CareerLazyQueryHookResult = ReturnType<typeof useCareerLazyQuery>;
export type CareerQueryResult = Apollo.QueryResult<CareerQuery, CareerQueryVariables>;
export const CareerSlugDocument = gql`
    query careerSlug {
  careers {
    data {
      attributes {
        slug
      }
    }
  }
}
    `;
export function useCareerSlugQuery(baseOptions?: Apollo.QueryHookOptions<CareerSlugQuery, CareerSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerSlugQuery, CareerSlugQueryVariables>(CareerSlugDocument, options);
      }
export function useCareerSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerSlugQuery, CareerSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerSlugQuery, CareerSlugQueryVariables>(CareerSlugDocument, options);
        }
export type CareerSlugQueryHookResult = ReturnType<typeof useCareerSlugQuery>;
export type CareerSlugLazyQueryHookResult = ReturnType<typeof useCareerSlugLazyQuery>;
export type CareerSlugQueryResult = Apollo.QueryResult<CareerSlugQuery, CareerSlugQueryVariables>;
export const CareerTypesDocument = gql`
    query careerTypes {
  careerTypes {
    data {
      attributes {
        type
      }
    }
  }
}
    `;
export function useCareerTypesQuery(baseOptions?: Apollo.QueryHookOptions<CareerTypesQuery, CareerTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareerTypesQuery, CareerTypesQueryVariables>(CareerTypesDocument, options);
      }
export function useCareerTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerTypesQuery, CareerTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareerTypesQuery, CareerTypesQueryVariables>(CareerTypesDocument, options);
        }
export type CareerTypesQueryHookResult = ReturnType<typeof useCareerTypesQuery>;
export type CareerTypesLazyQueryHookResult = ReturnType<typeof useCareerTypesLazyQuery>;
export type CareerTypesQueryResult = Apollo.QueryResult<CareerTypesQuery, CareerTypesQueryVariables>;
export const CategoryDocument = gql`
    query category($slug: StringFilterInput!) {
  categories(filters: {category: $slug}) {
    data {
      attributes {
        category
        cover {
          data {
            attributes {
              url
            }
          }
        }
        brochure {
          data {
            attributes {
              url
            }
          }
        }
        slides {
          data {
            attributes {
              ...SlideCard
            }
          }
        }
        brochures {
          data {
            attributes {
              ...Brochure
            }
          }
        }
        whitepapers {
          data {
            attributes {
              ...Whitepaper
            }
          }
        }
        customer_journeys {
          data {
            attributes {
              ...CustomerJourneyCard
            }
          }
        }
        challenges {
          data {
            attributes {
              ...ChallengeCard
            }
          }
        }
        blogs {
          data {
            attributes {
              ...BlogPostCard
            }
          }
        }
      }
    }
  }
}
    ${SlideCardFragmentDoc}
${FileFragmentDoc}
${BrochureFragmentDoc}
${WhitepaperFragmentDoc}
${CustomerJourneyCardFragmentDoc}
${ChallengeCardFragmentDoc}
${BlogPostCardFragmentDoc}`;
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const ChallengeDocument = gql`
    query challenge($slug: StringFilterInput!) {
  challenges(filters: {slug: $slug}) {
    data {
      id
      attributes {
        ...Challenge
      }
    }
  }
}
    ${ChallengeFragmentDoc}
${FileFragmentDoc}
${SeoFragmentDoc}`;
export function useChallengeQuery(baseOptions: Apollo.QueryHookOptions<ChallengeQuery, ChallengeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChallengeQuery, ChallengeQueryVariables>(ChallengeDocument, options);
      }
export function useChallengeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChallengeQuery, ChallengeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChallengeQuery, ChallengeQueryVariables>(ChallengeDocument, options);
        }
export type ChallengeQueryHookResult = ReturnType<typeof useChallengeQuery>;
export type ChallengeLazyQueryHookResult = ReturnType<typeof useChallengeLazyQuery>;
export type ChallengeQueryResult = Apollo.QueryResult<ChallengeQuery, ChallengeQueryVariables>;
export const ChannelpartnerDocument = gql`
    query channelpartner {
  channelPartner {
    data {
      id
      attributes {
        PageName
        isActive
        ChannelPartnerSections {
          ... on ComponentUiHero {
            ...Hero
          }
          ... on ComponentUiOpenContactForm {
            contactFormSection {
              ...Section
            }
            contactFormInputs {
              ...Input
            }
          }
          ... on ComponentUiListLinks {
            ...ListLinks
          }
          ... on ComponentSharedSeo {
            ...Seo
          }
          ... on ComponentSharedChannelPartnersSection {
            id
            SectionName
            sectionId
            backgroundColor
            isActive
            Title
            SubTitle
            Description
            CTA {
              id
              Title
              Redirect
              Target
              isActive
              CTAImage {
                data {
                  id
                  attributes {
                    name
                    alternativeText
                    url
                  }
                }
              }
            }
            Card {
              id
              Title
              Subtitle
              isActive
              Description
              Image {
                data {
                  id
                  attributes {
                    name
                    caption
                    url
                  }
                }
              }
              Target
              redirect
            }
          }
          ... on ComponentSharedCpCardSection {
            id
            SectionName
            sectionId
            backgroundColor
            isActive
            Title
            SubTitle
            Description
            CTA {
              id
              Title
              Redirect
              Target
              isActive
              CTAImage {
                data {
                  id
                  attributes {
                    name
                    caption
                    url
                  }
                }
              }
            }
            Image {
              data {
                id
                attributes {
                  name
                  caption
                  url
                }
              }
            }
          }
          ... on ComponentSharedCpFaq {
            id
            SectionName
            sectionId
            backgroundColor
            isActive
            Title
            SubTitle
            Description
            CTA {
              id
              Title
              Redirect
              Target
              CTAImage {
                data {
                  id
                  attributes {
                    name
                    caption
                    url
                  }
                }
              }
              isActive
            }
            ...CPfaqs
          }
          ... on ComponentUiD365CardSection {
            id
            SectionName
            backgroundColor
            isActive
            Title
            Description
            subTitle
            sectionCTA {
              id
              Title
              Redirect
              Target
              isActive
              CTAImage {
                data {
                  id
                  attributes {
                    name
                    url
                  }
                }
              }
            }
            D365Cards {
              id
              CardName
              isActive
              Banner {
                data {
                  id
                  attributes {
                    name
                    url
                  }
                }
              }
              Title
              Description
              redirectActive
              RedirectURL
              RedirectTitle
              subTitle
            }
          }
          ... on ComponentUiSubscribeSection {
            id
            SectionName
            backgroundColor
            isActive
            Title
            subTitle
            Description
            Media {
              data {
                id
                attributes {
                  name
                  url
                }
              }
            }
            CTA {
              id
              Title
              Redirect
              Target
              isActive
              CTAImage {
                data {
                  id
                  attributes {
                    name
                    url
                  }
                }
              }
            }
          }
          ... on ComponentUiMethodologySection {
            id
            backgroundColor
            isActive
            Title
            Description
            ProjectPhaseTitle
            ProjectDescriptionTitle
            methodologyProject {
              data {
                id
                attributes {
                  isActive
                  SectionName
                  PhaseTitle
                  ProjectDetailed {
                    id
                    Title
                    Description
                    isActive
                  }
                }
              }
            }
          }
          ... on ComponentUiD365ServiceSection {
            id
            SectionName
            backgroundColor
            isActive
            Title
            Description
            subTitle
            sectionCTA {
              id
              Title
              Redirect
              Target
              CTAImage {
                data {
                  id
                  attributes {
                    name
                    url
                  }
                }
              }
              isActive
            }
            ServiceCard {
              id
              isActive
              Title
              service_card_implementation {
                data {
                  id
                  attributes {
                    ServicesName
                    isActive
                    Title
                    Description
                    Media {
                      data {
                        id
                        attributes {
                          name
                          url
                        }
                      }
                    }
                    D365ServiceCard {
                      id
                      CardName
                      isActive
                      Title
                      D365CardList {
                        id
                        isActive
                        Icon {
                          data {
                            id
                            attributes {
                              name
                              url
                            }
                          }
                        }
                        ListName
                        Redirect
                        Description
                      }
                      Icon {
                        data {
                          id
                          attributes {
                            name
                            url
                          }
                        }
                      }
                    }
                    D365CTA {
                      id
                      Title
                      Redirect
                      Target
                      CTAImage {
                        data {
                          id
                          attributes {
                            name
                            url
                          }
                        }
                      }
                      isActive
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${HeroFragmentDoc}
${LinkFragmentDoc}
${TextFragmentDoc}
${SectionFragmentDoc}
${InputFragmentDoc}
${ListLinksFragmentDoc}
${SeoFragmentDoc}
${FileFragmentDoc}
${CPfaqsFragmentDoc}`;
export function useChannelpartnerQuery(baseOptions?: Apollo.QueryHookOptions<ChannelpartnerQuery, ChannelpartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelpartnerQuery, ChannelpartnerQueryVariables>(ChannelpartnerDocument, options);
      }
export function useChannelpartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelpartnerQuery, ChannelpartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelpartnerQuery, ChannelpartnerQueryVariables>(ChannelpartnerDocument, options);
        }
export type ChannelpartnerQueryHookResult = ReturnType<typeof useChannelpartnerQuery>;
export type ChannelpartnerLazyQueryHookResult = ReturnType<typeof useChannelpartnerLazyQuery>;
export type ChannelpartnerQueryResult = Apollo.QueryResult<ChannelpartnerQuery, ChannelpartnerQueryVariables>;
export const CustomerJourneyDocument = gql`
    query customerJourney($slug: StringFilterInput!) {
  customerJourneys(filters: {slug: $slug}) {
    data {
      id
      attributes {
        ...CustomerJourney
      }
    }
  }
}
    ${CustomerJourneyFragmentDoc}
${FileFragmentDoc}
${SeoFragmentDoc}`;
export function useCustomerJourneyQuery(baseOptions: Apollo.QueryHookOptions<CustomerJourneyQuery, CustomerJourneyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerJourneyQuery, CustomerJourneyQueryVariables>(CustomerJourneyDocument, options);
      }
export function useCustomerJourneyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerJourneyQuery, CustomerJourneyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerJourneyQuery, CustomerJourneyQueryVariables>(CustomerJourneyDocument, options);
        }
export type CustomerJourneyQueryHookResult = ReturnType<typeof useCustomerJourneyQuery>;
export type CustomerJourneyLazyQueryHookResult = ReturnType<typeof useCustomerJourneyLazyQuery>;
export type CustomerJourneyQueryResult = Apollo.QueryResult<CustomerJourneyQuery, CustomerJourneyQueryVariables>;
export const DarkHeaderThemePathDocument = gql`
    query darkHeaderThemePath {
  darkHeaderThemePaths {
    data {
      attributes {
        key
      }
    }
  }
}
    `;
export function useDarkHeaderThemePathQuery(baseOptions?: Apollo.QueryHookOptions<DarkHeaderThemePathQuery, DarkHeaderThemePathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DarkHeaderThemePathQuery, DarkHeaderThemePathQueryVariables>(DarkHeaderThemePathDocument, options);
      }
export function useDarkHeaderThemePathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DarkHeaderThemePathQuery, DarkHeaderThemePathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DarkHeaderThemePathQuery, DarkHeaderThemePathQueryVariables>(DarkHeaderThemePathDocument, options);
        }
export type DarkHeaderThemePathQueryHookResult = ReturnType<typeof useDarkHeaderThemePathQuery>;
export type DarkHeaderThemePathLazyQueryHookResult = ReturnType<typeof useDarkHeaderThemePathLazyQuery>;
export type DarkHeaderThemePathQueryResult = Apollo.QueryResult<DarkHeaderThemePathQuery, DarkHeaderThemePathQueryVariables>;
export const FooterDocument = gql`
    query footer {
  footer {
    data {
      attributes {
        logo {
          data {
            attributes {
              url
            }
          }
        }
        linkServices {
          ...Link
        }
        listServices {
          ...ListLinks
        }
        linkProducts {
          ...Link
        }
        listProducts {
          ...ListLinks
        }
        linkIndustries {
          ...Link
        }
        listIndustries {
          ...ListLinks
        }
        linkInsights {
          ...Link
        }
        listInsights {
          ...ListLinks
        }
        linkAboutUs {
          ...Link
        }
        listAboutUs {
          ...ListLinks
        }
        iconsSection {
          ...ListLinks
        }
        listAddress {
          ...TitleText
        }
        sectionInputBtn {
          ...SectionInputBtn
        }
        otherListLinks {
          ...ListLinks
        }
        listContacts {
          ...ListLinks
        }
      }
    }
  }
}
    ${LinkFragmentDoc}
${ListLinksFragmentDoc}
${TitleTextFragmentDoc}
${SectionInputBtnFragmentDoc}
${InputFragmentDoc}`;
export function useFooterQuery(baseOptions?: Apollo.QueryHookOptions<FooterQuery, FooterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FooterQuery, FooterQueryVariables>(FooterDocument, options);
      }
export function useFooterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FooterQuery, FooterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FooterQuery, FooterQueryVariables>(FooterDocument, options);
        }
export type FooterQueryHookResult = ReturnType<typeof useFooterQuery>;
export type FooterLazyQueryHookResult = ReturnType<typeof useFooterLazyQuery>;
export type FooterQueryResult = Apollo.QueryResult<FooterQuery, FooterQueryVariables>;
export const GetFormEmailDocument = gql`
    query getFormEmail {
  forms {
    data {
      attributes {
        email
      }
    }
  }
}
    `;
export function useGetFormEmailQuery(baseOptions?: Apollo.QueryHookOptions<GetFormEmailQuery, GetFormEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormEmailQuery, GetFormEmailQueryVariables>(GetFormEmailDocument, options);
      }
export function useGetFormEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormEmailQuery, GetFormEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormEmailQuery, GetFormEmailQueryVariables>(GetFormEmailDocument, options);
        }
export type GetFormEmailQueryHookResult = ReturnType<typeof useGetFormEmailQuery>;
export type GetFormEmailLazyQueryHookResult = ReturnType<typeof useGetFormEmailLazyQuery>;
export type GetFormEmailQueryResult = Apollo.QueryResult<GetFormEmailQuery, GetFormEmailQueryVariables>;
export const GlobalFooterDocument = gql`
    query GlobalFooter {
  globalFooter {
    data {
      id
      attributes {
        Footer {
          ... on ComponentUiNewsletter {
            title
            description
            ...CTALinksFragment
            ...SubscribeFormFragment
            isActive
          }
          ... on ComponentUiFooterMenu {
            id
            Title
            isActive
            ...FooterMenuFragment
          }
          ... on ComponentUiXpAddress {
            title
            address
            isActive
          }
          ... on ComponentUiFooterSecondaryMenu {
            ...FooterSecondaryMenuFragment
          }
          ... on ComponentUiFooterCopyRight {
            title
            copyRightLabel
            isActive
          }
          ... on ComponentUiXpSocialMedia {
            id
            title
            isActive
            ...FooterSocialMediaFragment
          }
        }
      }
    }
  }
}
    ${CtaLinksFragmentFragmentDoc}
${SubscribeFormFragmentFragmentDoc}
${FooterMenuFragmentFragmentDoc}
${FooterSecondaryMenuFragmentFragmentDoc}
${FooterSocialMediaFragmentFragmentDoc}`;
export function useGlobalFooterQuery(baseOptions?: Apollo.QueryHookOptions<GlobalFooterQuery, GlobalFooterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalFooterQuery, GlobalFooterQueryVariables>(GlobalFooterDocument, options);
      }
export function useGlobalFooterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalFooterQuery, GlobalFooterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalFooterQuery, GlobalFooterQueryVariables>(GlobalFooterDocument, options);
        }
export type GlobalFooterQueryHookResult = ReturnType<typeof useGlobalFooterQuery>;
export type GlobalFooterLazyQueryHookResult = ReturnType<typeof useGlobalFooterLazyQuery>;
export type GlobalFooterQueryResult = Apollo.QueryResult<GlobalFooterQuery, GlobalFooterQueryVariables>;
export const GlobalHeaderDocument = gql`
    query GlobalHeader {
  globalHeader {
    data {
      id
      attributes {
        ComponentName
        ComponentID
        Navbar {
          ... on ComponentUiAlertBanner {
            id
            isActive
            alertMessage {
              Title
              Description
              Redirect
              isActive
              Icon {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
          ... on ComponentUiTopMenu {
            id
            isActive
            TopLinks {
              id
              Title
              Redirect
              isActive
              Target
              Icon {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
          ... on ComponentUiLogo {
            id
            Title
            darkLogo {
              data {
                attributes {
                  url
                }
              }
            }
            lightLogo {
              data {
                attributes {
                  url
                }
              }
            }
          }
          ... on ComponentUiMainMenu {
            id
            isActive
            LinksMain {
              id
              Title
              Redirect
              isActive
              Target
              isDropDown
              Icon {
                data {
                  attributes {
                    url
                  }
                }
              }
              drop_menu_lists {
                data {
                  id
                  attributes {
                    Title
                    LinkID
                    isActive
                    Redirect
                    Target
                    dropLinks {
                      id
                      Title
                      Redirect
                      isActive
                      Target
                      Icon {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                    Icon {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
              drop_menu_list_secondaries {
                data {
                  id
                  attributes {
                    Title
                    LinkID
                    isActive
                    Redirect
                    Target
                    dropLinks {
                      id
                      Title
                      Redirect
                      isActive
                      Target
                      Icon {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                    Icon {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
              drop_cards {
                data {
                  id
                  attributes {
                    Title
                    isActive
                    Description
                    SubTitle
                    redirect
                    CardBTN {
                      id
                      Title
                      Redirect
                      Target
                      isActive
                      CTAImage {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                    Banner {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on ComponentUiMenuCta {
            id
            isActive
            menuCTA {
              id
              Title
              Redirect
              Target
              isActive
              CTAImage {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
          ... on ComponentUiMenuIcon {
            id
            isActive
            menuIcon {
              Icon {
                data {
                  id
                  attributes {
                    alternativeText
                    caption
                    url
                  }
                }
              }
              Redirect
              Target
              Title
              id
              isActive
            }
          }
        }
      }
    }
  }
}
    `;
export function useGlobalHeaderQuery(baseOptions?: Apollo.QueryHookOptions<GlobalHeaderQuery, GlobalHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalHeaderQuery, GlobalHeaderQueryVariables>(GlobalHeaderDocument, options);
      }
export function useGlobalHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalHeaderQuery, GlobalHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalHeaderQuery, GlobalHeaderQueryVariables>(GlobalHeaderDocument, options);
        }
export type GlobalHeaderQueryHookResult = ReturnType<typeof useGlobalHeaderQuery>;
export type GlobalHeaderLazyQueryHookResult = ReturnType<typeof useGlobalHeaderLazyQuery>;
export type GlobalHeaderQueryResult = Apollo.QueryResult<GlobalHeaderQuery, GlobalHeaderQueryVariables>;
export const HeaderDocument = gql`
    query header {
  header {
    data {
      attributes {
        authorizationLinks {
          ...Link
        }
        firstMenuTitle {
          ...Link
        }
        firstMenuCard {
          ...Card
        }
        firstMenuSectionsCards {
          ...SectionCards
        }
        firstMenuSectionsCardsOpenItem {
          ...ListLinks
        }
        secondMenuTitle {
          ...Link
        }
        secondMenuCard {
          ...Card
        }
        secondMenuSectionsCards {
          ...SectionCards
        }
        secondMenuSectionsCardsOpenItem {
          ...ListLinks
        }
        thirdMenuTitle {
          ...Link
        }
        thirdMenuCard {
          ...Card
        }
        thirdMenuSectionsCards {
          ...SectionCards
        }
        fourthMenuTitle {
          ...Link
        }
        fourthMenuSectionsCards {
          ...SectionCards
        }
        fourthMenuSectionLinks {
          ...SectionCards
        }
        fifthMenuTitle {
          ...Link
        }
        fifthMenuCard {
          ...Card
        }
        fifthMenuSectionsCards {
          ...SectionCards
        }
        whyXcelPros {
          ...Link
        }
      }
    }
  }
}
    ${LinkFragmentDoc}
${CardFragmentDoc}
${SectionCardsFragmentDoc}
${ListLinksFragmentDoc}`;
export function useHeaderQuery(baseOptions?: Apollo.QueryHookOptions<HeaderQuery, HeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeaderQuery, HeaderQueryVariables>(HeaderDocument, options);
      }
export function useHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeaderQuery, HeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeaderQuery, HeaderQueryVariables>(HeaderDocument, options);
        }
export type HeaderQueryHookResult = ReturnType<typeof useHeaderQuery>;
export type HeaderLazyQueryHookResult = ReturnType<typeof useHeaderLazyQuery>;
export type HeaderQueryResult = Apollo.QueryResult<HeaderQuery, HeaderQueryVariables>;
export const IconLinkDocument = gql`
    query iconLink {
  iconLink {
    data {
      attributes {
        links {
          ...Link
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export function useIconLinkQuery(baseOptions?: Apollo.QueryHookOptions<IconLinkQuery, IconLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IconLinkQuery, IconLinkQueryVariables>(IconLinkDocument, options);
      }
export function useIconLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IconLinkQuery, IconLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IconLinkQuery, IconLinkQueryVariables>(IconLinkDocument, options);
        }
export type IconLinkQueryHookResult = ReturnType<typeof useIconLinkQuery>;
export type IconLinkLazyQueryHookResult = ReturnType<typeof useIconLinkLazyQuery>;
export type IconLinkQueryResult = Apollo.QueryResult<IconLinkQuery, IconLinkQueryVariables>;
export const IndustryDocument = gql`
    query industry($key: StringFilterInput!) {
  industries(filters: {key: $key}) {
    data {
      attributes {
        key
        hero {
          ...Hero
        }
        cardsNumber {
          ...SectionCards
        }
        cardsFullWidth {
          ...SectionCards
        }
        listOpeningCardsImg {
          ...SectionCards
        }
        cardsImg {
          ...SectionCards
        }
        cardsBorderBetween {
          ...SectionCards
        }
        sectionTitleTextSecond {
          ...TitleText
        }
        formContactSection {
          ...Section
        }
        formContactInputs {
          ...Input
        }
        trendingInsight
        trendingInsightBckColor
        seo {
          ...Seo
        }
      }
    }
  }
}
    ${HeroFragmentDoc}
${LinkFragmentDoc}
${TextFragmentDoc}
${SectionCardsFragmentDoc}
${CardFragmentDoc}
${TitleTextFragmentDoc}
${SectionFragmentDoc}
${InputFragmentDoc}
${SeoFragmentDoc}
${FileFragmentDoc}`;
export function useIndustryQuery(baseOptions: Apollo.QueryHookOptions<IndustryQuery, IndustryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustryQuery, IndustryQueryVariables>(IndustryDocument, options);
      }
export function useIndustryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustryQuery, IndustryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustryQuery, IndustryQueryVariables>(IndustryDocument, options);
        }
export type IndustryQueryHookResult = ReturnType<typeof useIndustryQuery>;
export type IndustryLazyQueryHookResult = ReturnType<typeof useIndustryLazyQuery>;
export type IndustryQueryResult = Apollo.QueryResult<IndustryQuery, IndustryQueryVariables>;
export const IndustrySlugDocument = gql`
    query industrySlug {
  industries {
    data {
      attributes {
        key
      }
    }
  }
}
    `;
export function useIndustrySlugQuery(baseOptions?: Apollo.QueryHookOptions<IndustrySlugQuery, IndustrySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustrySlugQuery, IndustrySlugQueryVariables>(IndustrySlugDocument, options);
      }
export function useIndustrySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustrySlugQuery, IndustrySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustrySlugQuery, IndustrySlugQueryVariables>(IndustrySlugDocument, options);
        }
export type IndustrySlugQueryHookResult = ReturnType<typeof useIndustrySlugQuery>;
export type IndustrySlugLazyQueryHookResult = ReturnType<typeof useIndustrySlugLazyQuery>;
export type IndustrySlugQueryResult = Apollo.QueryResult<IndustrySlugQuery, IndustrySlugQueryVariables>;
export const InsightMeetingCardDocument = gql`
    query insightMeetingCard {
  insightMeetingCard {
    data {
      attributes {
        card {
          ...TitleText
        }
      }
    }
  }
}
    ${TitleTextFragmentDoc}
${LinkFragmentDoc}`;
export function useInsightMeetingCardQuery(baseOptions?: Apollo.QueryHookOptions<InsightMeetingCardQuery, InsightMeetingCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightMeetingCardQuery, InsightMeetingCardQueryVariables>(InsightMeetingCardDocument, options);
      }
export function useInsightMeetingCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightMeetingCardQuery, InsightMeetingCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightMeetingCardQuery, InsightMeetingCardQueryVariables>(InsightMeetingCardDocument, options);
        }
export type InsightMeetingCardQueryHookResult = ReturnType<typeof useInsightMeetingCardQuery>;
export type InsightMeetingCardLazyQueryHookResult = ReturnType<typeof useInsightMeetingCardLazyQuery>;
export type InsightMeetingCardQueryResult = Apollo.QueryResult<InsightMeetingCardQuery, InsightMeetingCardQueryVariables>;
export const InsightTypesDocument = gql`
    query insightTypes {
  insightTypes {
    data {
      attributes {
        type
      }
    }
  }
}
    `;
export function useInsightTypesQuery(baseOptions?: Apollo.QueryHookOptions<InsightTypesQuery, InsightTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightTypesQuery, InsightTypesQueryVariables>(InsightTypesDocument, options);
      }
export function useInsightTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightTypesQuery, InsightTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightTypesQuery, InsightTypesQueryVariables>(InsightTypesDocument, options);
        }
export type InsightTypesQueryHookResult = ReturnType<typeof useInsightTypesQuery>;
export type InsightTypesLazyQueryHookResult = ReturnType<typeof useInsightTypesLazyQuery>;
export type InsightTypesQueryResult = Apollo.QueryResult<InsightTypesQuery, InsightTypesQueryVariables>;
export const LightHeaderThemePageDocument = gql`
    query lightHeaderThemePage {
  lightHeaderThemePages {
    data {
      attributes {
        key
      }
    }
  }
}
    `;
export function useLightHeaderThemePageQuery(baseOptions?: Apollo.QueryHookOptions<LightHeaderThemePageQuery, LightHeaderThemePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LightHeaderThemePageQuery, LightHeaderThemePageQueryVariables>(LightHeaderThemePageDocument, options);
      }
export function useLightHeaderThemePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LightHeaderThemePageQuery, LightHeaderThemePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LightHeaderThemePageQuery, LightHeaderThemePageQueryVariables>(LightHeaderThemePageDocument, options);
        }
export type LightHeaderThemePageQueryHookResult = ReturnType<typeof useLightHeaderThemePageQuery>;
export type LightHeaderThemePageLazyQueryHookResult = ReturnType<typeof useLightHeaderThemePageLazyQuery>;
export type LightHeaderThemePageQueryResult = Apollo.QueryResult<LightHeaderThemePageQuery, LightHeaderThemePageQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const OurProductsHeaderThemePageDocument = gql`
    query ourProductsHeaderThemePage {
  ourProductsHeaderThemePages {
    data {
      attributes {
        key
      }
    }
  }
}
    `;
export function useOurProductsHeaderThemePageQuery(baseOptions?: Apollo.QueryHookOptions<OurProductsHeaderThemePageQuery, OurProductsHeaderThemePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OurProductsHeaderThemePageQuery, OurProductsHeaderThemePageQueryVariables>(OurProductsHeaderThemePageDocument, options);
      }
export function useOurProductsHeaderThemePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OurProductsHeaderThemePageQuery, OurProductsHeaderThemePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OurProductsHeaderThemePageQuery, OurProductsHeaderThemePageQueryVariables>(OurProductsHeaderThemePageDocument, options);
        }
export type OurProductsHeaderThemePageQueryHookResult = ReturnType<typeof useOurProductsHeaderThemePageQuery>;
export type OurProductsHeaderThemePageLazyQueryHookResult = ReturnType<typeof useOurProductsHeaderThemePageLazyQuery>;
export type OurProductsHeaderThemePageQueryResult = Apollo.QueryResult<OurProductsHeaderThemePageQuery, OurProductsHeaderThemePageQueryVariables>;
export const PageDocument = gql`
    query page {
  page {
    data {
      ...Page
    }
  }
}
    ${PageFragmentDoc}
${NewsFragmentDoc}
${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${SeoFragmentDoc}
${FileFragmentDoc}
${HomeFragmentDoc}
${InputFragmentDoc}
${CultureFragmentDoc}
${TextFragmentDoc}
${PrivacyPolicyFragmentDoc}
${PolicySectionFragmentDoc}
${ServicePackagesOffersFragmentDoc}
${EulaFragmentDoc}
${AgreementIntroFragmentDoc}
${AgreementItemFragmentDoc}
${AgreementSubitemFragmentDoc}
${AboutFragmentDoc}
${SitemapFragmentDoc}
${LinksGroupFragmentDoc}
${CookieFragmentDoc}
${ContactUsFragmentDoc}
${FaqFragmentDoc}
${WhyXcelProsFragmentDoc}
${PageShortFragmentDoc}
${IndustryFragmentDoc}
${TestimonialsFragmentDoc}
${ProductsFragmentDoc}
${ServicesFragmentDoc}
${ScheduleMeetingFragmentDoc}
${WriteForUsFragmentDoc}
${EventsFragmentDoc}`;
export function usePageQuery(baseOptions?: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options);
      }
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, options);
        }
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>;
export const PopUpContactUsDocument = gql`
    query popUpContactUs {
  popUpContactUs {
    data {
      attributes {
        section {
          ...SectionInputBtn
        }
      }
    }
  }
}
    ${SectionInputBtnFragmentDoc}
${InputFragmentDoc}`;
export function usePopUpContactUsQuery(baseOptions?: Apollo.QueryHookOptions<PopUpContactUsQuery, PopUpContactUsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PopUpContactUsQuery, PopUpContactUsQueryVariables>(PopUpContactUsDocument, options);
      }
export function usePopUpContactUsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PopUpContactUsQuery, PopUpContactUsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PopUpContactUsQuery, PopUpContactUsQueryVariables>(PopUpContactUsDocument, options);
        }
export type PopUpContactUsQueryHookResult = ReturnType<typeof usePopUpContactUsQuery>;
export type PopUpContactUsLazyQueryHookResult = ReturnType<typeof usePopUpContactUsLazyQuery>;
export type PopUpContactUsQueryResult = Apollo.QueryResult<PopUpContactUsQuery, PopUpContactUsQueryVariables>;
export const PopUpSubscribeDocument = gql`
    query popUpSubscribe {
  popUpSubscribe {
    data {
      attributes {
        section {
          ...SectionInputBtn
        }
      }
    }
  }
}
    ${SectionInputBtnFragmentDoc}
${InputFragmentDoc}`;
export function usePopUpSubscribeQuery(baseOptions?: Apollo.QueryHookOptions<PopUpSubscribeQuery, PopUpSubscribeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PopUpSubscribeQuery, PopUpSubscribeQueryVariables>(PopUpSubscribeDocument, options);
      }
export function usePopUpSubscribeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PopUpSubscribeQuery, PopUpSubscribeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PopUpSubscribeQuery, PopUpSubscribeQueryVariables>(PopUpSubscribeDocument, options);
        }
export type PopUpSubscribeQueryHookResult = ReturnType<typeof usePopUpSubscribeQuery>;
export type PopUpSubscribeLazyQueryHookResult = ReturnType<typeof usePopUpSubscribeLazyQuery>;
export type PopUpSubscribeQueryResult = Apollo.QueryResult<PopUpSubscribeQuery, PopUpSubscribeQueryVariables>;
export const ProductDocument = gql`
    query product($key: StringFilterInput!) {
  products(filters: {key: $key}) {
    data {
      attributes {
        key
        style
        productRelation
        hero {
          ...Hero
        }
        carouselLinksNavigation {
          ...ListLinks
        }
        carouselLinks {
          ...ListLinks
        }
        sectionNavigate {
          ...SectionCards
        }
        listOpeningCardsZero {
          ...SectionCards
        }
        sectionTitleTextFirst {
          ...TitleText
        }
        cardsWidth30UnderlineTopBottom {
          ...SectionCards
        }
        sectionUnderline {
          ...Section
        }
        cardsUnderlineSection {
          ...SectionCards
        }
        linkUnderlineSection {
          ...Link
        }
        cardsOpeningIcon {
          ...SectionCards
        }
        sectionTitleTextSecondZero {
          ...TitleText
        }
        sectionTitleTextSecond {
          ...TitleText
        }
        cardsBorderBetween {
          ...SectionCards
        }
        sectionTitleTextList {
          ...SectionCards
        }
        listNumberLinks {
          ...ListLinks
        }
        cardsUnderlineTitle {
          ...SectionCards
        }
        cardsWidth30Section {
          ...Section
        }
        cardsWidth30Cards {
          ...Card
        }
        cardsFullWidthSection {
          ...Section
        }
        cardsFullWidthCards {
          ...Card
        }
        cardsWidth30TextList {
          ...SectionCards
        }
        listOpeningCardsFirst {
          ...SectionCards
        }
        sectionTitleLink {
          ...ListLinks
        }
        linksHalfWidthSection {
          ...ListLinks
        }
        sectionChapters {
          ...SectionChapters
        }
        sectionChaptersLinks {
          ...ListLinks
        }
        cardsFullWidthOpen {
          ...SectionCards
        }
        cardsBorderTop {
          ...SectionCards
        }
        carouselListImg {
          ...SectionCards
        }
        listOpeningCardsSecond {
          ...SectionCards
        }
        sectionList {
          ...SectionTextList
        }
        linksList {
          ...ListLinks
        }
        sectionTestimonial {
          ...SectionTestimonial
        }
        firstSectionImgMultipleImages {
          ...SectionImg
        }
        firstCardMultipleImages {
          ...Card
        }
        firstListOpeningCardsMultipleImages {
          ...SectionCards
        }
        secondSectionImgMultipleImages {
          ...SectionImg
        }
        secondCardMultipleImages {
          ...Card
        }
        secondListOpeningCardsMultipleImages {
          ...SectionCards
        }
        thirdSectionImgMultipleImages {
          ...SectionImg
        }
        thirdCardMultipleImages {
          ...Card
        }
        thirdListOpeningCardsMultipleImages {
          ...SectionCards
        }
        sectionTitleTextThirdZero {
          ...TitleText
        }
        listOpeningCards {
          ...SectionCards
        }
        sectionTextList {
          ...SectionTextList
        }
        cardsBorderBetweenSecond {
          ...SectionCards
        }
        sectionTitleTextThird {
          ...TitleText
        }
        formContactSection {
          ...Section
        }
        formContactInputs {
          ...Input
        }
        scrollableContentSection {
          sectionId
          backgroundColor
          card {
            ...Card
          }
        }
        trendingInsight
        trendingInsightBckColor
        seo {
          ...Seo
        }
      }
    }
  }
}
    ${HeroFragmentDoc}
${LinkFragmentDoc}
${TextFragmentDoc}
${ListLinksFragmentDoc}
${SectionCardsFragmentDoc}
${CardFragmentDoc}
${TitleTextFragmentDoc}
${SectionFragmentDoc}
${SectionChaptersFragmentDoc}
${SectionTextListFragmentDoc}
${TextListFragmentDoc}
${SectionTestimonialFragmentDoc}
${TestimonialsFragmentDoc}
${SectionImgFragmentDoc}
${InputFragmentDoc}
${SeoFragmentDoc}
${FileFragmentDoc}`;
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductSlugDocument = gql`
    query productSlug {
  products {
    data {
      attributes {
        key
      }
    }
  }
}
    `;
export function useProductSlugQuery(baseOptions?: Apollo.QueryHookOptions<ProductSlugQuery, ProductSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSlugQuery, ProductSlugQueryVariables>(ProductSlugDocument, options);
      }
export function useProductSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSlugQuery, ProductSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSlugQuery, ProductSlugQueryVariables>(ProductSlugDocument, options);
        }
export type ProductSlugQueryHookResult = ReturnType<typeof useProductSlugQuery>;
export type ProductSlugLazyQueryHookResult = ReturnType<typeof useProductSlugLazyQuery>;
export type ProductSlugQueryResult = Apollo.QueryResult<ProductSlugQuery, ProductSlugQueryVariables>;
export const ServiceDocument = gql`
    query service($key: StringFilterInput!) {
  services(filters: {key: $key}) {
    data {
      attributes {
        key
        style
        hero {
          ...Hero
        }
        titleUnderlineSection
        cardsUnderlineSection {
          ...SectionCards
        }
        linkUnderlineSection {
          ...Link
        }
        cardsOpeningIcon {
          ...SectionCards
        }
        sectionTitleText {
          ...TitleText
        }
        cardsWidth30Index {
          ...SectionCards
        }
        cardsImg {
          ...SectionCards
        }
        sectionTitleTextFirst {
          ...TitleText
        }
        cardsFullWidth {
          ...SectionCards
        }
        formContactSection {
          ...Section
        }
        formContactInputs {
          ...Input
        }
        trendingInsight
        trendingInsightBckColor
        seo {
          ...Seo
        }
      }
    }
  }
}
    ${HeroFragmentDoc}
${LinkFragmentDoc}
${TextFragmentDoc}
${SectionCardsFragmentDoc}
${CardFragmentDoc}
${TitleTextFragmentDoc}
${SectionFragmentDoc}
${InputFragmentDoc}
${SeoFragmentDoc}
${FileFragmentDoc}`;
export function useServiceQuery(baseOptions: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
      }
export function useServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceQueryResult = Apollo.QueryResult<ServiceQuery, ServiceQueryVariables>;
export const ServiceSlugDocument = gql`
    query serviceSlug {
  services {
    data {
      attributes {
        key
      }
    }
  }
}
    `;
export function useServiceSlugQuery(baseOptions?: Apollo.QueryHookOptions<ServiceSlugQuery, ServiceSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceSlugQuery, ServiceSlugQueryVariables>(ServiceSlugDocument, options);
      }
export function useServiceSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceSlugQuery, ServiceSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceSlugQuery, ServiceSlugQueryVariables>(ServiceSlugDocument, options);
        }
export type ServiceSlugQueryHookResult = ReturnType<typeof useServiceSlugQuery>;
export type ServiceSlugLazyQueryHookResult = ReturnType<typeof useServiceSlugLazyQuery>;
export type ServiceSlugQueryResult = Apollo.QueryResult<ServiceSlugQuery, ServiceSlugQueryVariables>;
export const SlideDocument = gql`
    query slide($slug: StringFilterInput!) {
  slides(filters: {slug: $slug}) {
    data {
      id
      attributes {
        ...Slide
      }
    }
  }
}
    ${SlideFragmentDoc}
${FileFragmentDoc}
${SeoFragmentDoc}`;
export function useSlideQuery(baseOptions: Apollo.QueryHookOptions<SlideQuery, SlideQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlideQuery, SlideQueryVariables>(SlideDocument, options);
      }
export function useSlideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlideQuery, SlideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlideQuery, SlideQueryVariables>(SlideDocument, options);
        }
export type SlideQueryHookResult = ReturnType<typeof useSlideQuery>;
export type SlideLazyQueryHookResult = ReturnType<typeof useSlideLazyQuery>;
export type SlideQueryResult = Apollo.QueryResult<SlideQuery, SlideQueryVariables>;
export const TagDocument = gql`
    query tag($slug: StringFilterInput!) {
  blogTags(filters: {tag: $slug}) {
    data {
      attributes {
        tag
        cover {
          data {
            attributes {
              url
            }
          }
        }
        slides {
          data {
            attributes {
              ...SlideCard
            }
          }
        }
        blogs {
          data {
            attributes {
              ...BlogPostCard
            }
          }
        }
      }
    }
  }
}
    ${SlideCardFragmentDoc}
${FileFragmentDoc}
${BlogPostCardFragmentDoc}`;
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const TrendingBlogPostCardDocument = gql`
    query trendingBlogPostCard {
  blogPosts(
    filters: {trending_categories: {category: {ne: null}}}
    sort: "id:desc"
  ) {
    data {
      attributes {
        ...BlogPostCard
      }
    }
  }
}
    ${BlogPostCardFragmentDoc}
${FileFragmentDoc}`;
export function useTrendingBlogPostCardQuery(baseOptions?: Apollo.QueryHookOptions<TrendingBlogPostCardQuery, TrendingBlogPostCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrendingBlogPostCardQuery, TrendingBlogPostCardQueryVariables>(TrendingBlogPostCardDocument, options);
      }
export function useTrendingBlogPostCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrendingBlogPostCardQuery, TrendingBlogPostCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrendingBlogPostCardQuery, TrendingBlogPostCardQueryVariables>(TrendingBlogPostCardDocument, options);
        }
export type TrendingBlogPostCardQueryHookResult = ReturnType<typeof useTrendingBlogPostCardQuery>;
export type TrendingBlogPostCardLazyQueryHookResult = ReturnType<typeof useTrendingBlogPostCardLazyQuery>;
export type TrendingBlogPostCardQueryResult = Apollo.QueryResult<TrendingBlogPostCardQuery, TrendingBlogPostCardQueryVariables>;
export const WebsiteDocument = gql`
    query website {
  website {
    data {
      attributes {
        ...Website
      }
    }
  }
}
    ${WebsiteFragmentDoc}
${SeoFragmentDoc}
${FileFragmentDoc}`;
export function useWebsiteQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteQuery, WebsiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteQuery, WebsiteQueryVariables>(WebsiteDocument, options);
      }
export function useWebsiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteQuery, WebsiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteQuery, WebsiteQueryVariables>(WebsiteDocument, options);
        }
export type WebsiteQueryHookResult = ReturnType<typeof useWebsiteQuery>;
export type WebsiteLazyQueryHookResult = ReturnType<typeof useWebsiteLazyQuery>;
export type WebsiteQueryResult = Apollo.QueryResult<WebsiteQuery, WebsiteQueryVariables>;