import { initMarker } from "@bn-digital/sdk"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import App from "./components/app"
import { markerId } from "./components/constants"

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/serviceWorker.js')
      .then(registration => {
        console.log('ServiceWorker registration successful');
      })
      .catch(err => {
        console.log('ServiceWorker registration failed: ', err);
      });
  });
}

// Promise.resolve(import.meta.env.MODE === "production").then(enabled => initMarker({ enabled, destination: markerId }))
Promise.resolve(import.meta.env.MODE === "production").then(enabled => {
  initMarker({ enabled: true, destination: "660af5554647253a5b4e0e2b",
             })
})

const rootElement = document.querySelector("#root") as HTMLElement

createRoot(rootElement).render(
  <StrictMode>
    <App />
  </StrictMode>
)
